import React, { useEffect, useState } from "react";
import {Carousel, Layout} from "antd";
import { useRouter } from 'next/router';
import styles from './home/index.module.scss';
import {NextSeo} from "next-seo";
import {useTranslation} from "react-i18next";
import ReactPlayer from "react-player/lazy";
import {APIGetAndroidDownloadLink, APIGetHomePageBanner, APIGetHomePageStatics} from "@/api";
import GamieZone from "@/pages/discover";
import {defaultHeadPic, getCookieByString, getQueryString, numFormat} from "@/tools/action";
import {object} from "prop-types";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import useDispatchAction from "@/hooks/useDisptachAction";
import {
  addToNumber,
  reset,
  setAllowPointsExchangeIGM,
  setCoinsBalance,
  setExpiringPoints,
  setIGMBalance, setIGMTransactionModal, setLoginShowModal, setNftSellHidden, setNftSellShow
} from "@/redux/actions/home";
import {setUserInfo} from "@/redux/actions/userInfo";
const Home = ({homeStatics, homeBanner}) => {
  const router = useRouter();
  let { t, i18n} = useTranslation();
  const [playing, setPlaying] = useState(false);
  const pushZone = () => {
    router.push('discover');
  };
  const pushGenerate = () => {
    router.push('generate')
  };
  const dispatchAction = useDispatchAction({ setLoginShowModal});
  const onAndroidDownloadBtnClick = (e) => {
    APIGetAndroidDownloadLink().then(resp => {
      if (resp.success) {
        window.location.href = resp.result.url
      }
    })
  };


  // return <GamieZone></GamieZone>;

  // return (
  //   <>
  //     <NextSeo
  //       title={`imomo - Generate Any AI Arts You Love`}
  //       canonical={ router.locale === 'en' ? `${process.env.BASE_URL}${router.asPath} ` : `${process.env.BASE_URL}/${router.locale}${router.asPath}`}
  //       description={ `imomo - Generate Any AI Arts You Love, An AI Art Platform designed for VTubers, anime enthusiasts, and fans of the 2D culture` }
  //       openGraph={{
  //         type: 'website',
  //         locale: router.locale,
  //         url:  router.locale === 'en' ? `${process.env.BASE_URL}${router.asPath} ` : `${process.env.BASE_URL}/${router.locale}${router.asPath}`,
  //         description: 'imomo - Generate Any AI Arts You Love',
  //         siteName: 'imomo',
  //         images: [
  //           {
  //             url: 'https://imomo.ai/favicon.ico',
  //             width: 1200,
  //             height: 800,
  //             alt: 'imomo discover is the place to get the best gaming content from popular games. Browse top trending posts on their favourite game all on a single platform.',
  //           },
  //         ],
  //       }}
  //       facebook={{
  //         appId:"2170943866417417"
  //       }}
  //       twitter={{
  //         handle: '@imomo',
  //         site: '@imomo',
  //         cardType: 'summary_large_image',
  //       }}
  //     />
  //     <Layout className={`animated fadeIn animate__fadeInUp ${styles.home_page}`}>
  //       <svg className={styles.home_bg_svg} fill='none' xmlns='http://www.w3.org/2000/svg' >
  //         <g clip-path='url(#clip0_402_544)'>
  //           <rect width='100%' height='100%' fill='url(#paint0_linear_402_544)'></rect>
  //         </g>
  //         <defs>
  //           <linearGradient id='paint0_linear_402_544' x1='1304' y1='1.27213e-05' x2='741.984' y2='765.466' gradientUnits='userSpaceOnUse'>
  //             <stop offset='0.00101833' stop-color='#c36a92'></stop><stop offset='1' stop-opacity='0'></stop>
  //           </linearGradient>
  //           <clipPath id='clip0_402_544'>
  //             <rect width='100%' height='100%' fill='white'></rect>
  //           </clipPath>
  //         </defs>
  //       </svg>
  //
  //       <div className={styles.introduce_wrapper}>
  //         <h1 className={styles.title}>
  //           {t('imomoHomeTopTitle')}
  //         </h1>
  //         <div className={styles.action_wra}>
  //           <div onClick={pushZone} className={styles.discover_btn}>
  //             <div className={styles.icon}></div>
  //             <span>{t('imomoDiscover')}</span>
  //           </div>
  //           <div onClick={pushGenerate} className={styles.generate_btn}>
  //             <div className={styles.icon}></div>
  //             <span>{t('imomoGenerate')}</span>
  //           </div>
  //         </div>
  //       </div>
  //       <div className={styles.app_download_wrapper}>
  //         <a target={'_blank'} href={'https://apps.apple.com/sg/app/imomo/id6452010316'}>
  //           <div className={styles.apple_download}></div>
  //         </a>
  //         <div onClick={onAndroidDownloadBtnClick} className={styles.android_download}></div>
  //       </div>
  //       <div className={styles.moddle_wra}>
  //         <div className={styles.video_wra}>
  //           <div className={styles.video_box}>
  //             <ReactPlayer id={"video"}
  //                          playing={true}
  //                          controls={true}
  //                          loop={true}
  //                          width={"100%"}
  //                          onReady={() => {
  //
  //                          }}
  //                          volume={0}
  //                          height={"100%"}
  //                          url={'/home_video.mp4'}/>
  //           </div>
  //         </div>
  //         <div className={styles.sub_title}>
  //           <h2 className={styles.text}>
  //             {t('imomoHomeSubTitle')}
  //           </h2>
  //         </div>
  //       </div>
  //       <div className={styles.banner_wrapper}>
  //         <div className={styles.box_wra}></div>
  //         <div className={styles.banner}></div>
  //         <div className={styles.text_wra}>
  //           <h2 className={styles.text}>
  //             {t('imomoHomeBottomTitle')}
  //           </h2>
  //         </div>
  //       </div>
  //     </Layout>
  //   </>
  // );

  const toKOLUser = (item) => {
    console.log(item, "userRecommended userId")
    console.log(homeBanner, "userRecommended homeBanner")
    router.push('/personal-center/[userId]', `/personal-center/${item.id}`, {locale: router.locale});
  };

  useEffect(()=>{
   const inviteCode = getQueryString("inviteCode");
   if (inviteCode){
     dispatchAction.setLoginShowModal(true);
   }
  }, []);

  return <>
    <NextSeo
      title={`imomo - Generate Any AI Arts You Love`}
      canonical={ router.locale === 'en' ? `${process.env.BASE_URL}${router.asPath} ` : `${process.env.BASE_URL}/${router.locale}${router.asPath}`}
      description={ `imomo - Generate Any AI Arts You Love, An AI Art Platform designed for VTubers, anime enthusiasts, and fans of the 2D culture` }
      openGraph={{
        type: 'website',
        locale: router.locale,
        url:  router.locale === 'en' ? `${process.env.BASE_URL}${router.asPath} ` : `${process.env.BASE_URL}/${router.locale}${router.asPath}`,
        description: 'imomo - Generate Any AI Arts You Love',
        siteName: 'imomo',
        images: [
          {
            url: 'https://imomo.ai/favicon.ico',
            width: 1200,
            height: 800,
            alt: 'imomo discover is the place to get the best gaming content from popular games. Browse top trending posts on their favourite game all on a single platform.',
          },
        ],
      }}
      facebook={{
        appId:"2170943866417417"
      }}
      twitter={{
        handle: '@imomo',
        site: '@imomo',
        cardType: 'summary_large_image',
      }}
    />
    <Layout className={`animated fadeIn animate__fadeInUp ${styles.home_page}`}>
     <svg className={styles.home_bg_svg} fill='none' xmlns='http://www.w3.org/2000/svg' >
       <g clip-path='url(#clip0_402_544)'>
         <rect width='100%' height='100%' fill='url(#paint0_linear_402_544)'></rect>
       </g>
       <defs>
         <linearGradient id='paint0_linear_402_544' x1='1304' y1='1.27213e-05' x2='741.984' y2='765.466' gradientUnits='userSpaceOnUse'>
           <stop offset='0.00101833' stop-color='#c36a92'></stop><stop offset='1' stop-opacity='0'></stop>
         </linearGradient>
         <clipPath id='clip0_402_544'>
           <rect width='100%' height='100%' fill='white'></rect>
         </clipPath>
       </defs>
     </svg>
      <div className={styles.new_home_wrapper}>
        <div className={styles.new_home_body_wrap}>
          <div className={styles.left_wrapper}>
            <div className={styles.title_home}>AI x Web3</div>
            <div className={`${styles.title_home} ${styles.sub_title_home}`}>
              {t("The only limit is your imagination")}
            </div>
            <div className={styles.des_home}>
              {
                t("home_des_1")
              }
              <br/>
              {
                t("home_des_2")
              }
            </div>
            <div className={styles.home_btn_wrap}>
              <div onClick={()=>{
                window.open("https://imomo-ai.gitbook.io/whitepaper/", "blank");
              }} className={styles.white_btn}>{t("WhitePaper")}</div>
              <div onClick={()=>router.push("/discover")} className={styles.discover_btn_wrap}>{t("Discovery")}</div>
            </div>
            <div className={styles.home_btn_wrap}>
              <div className={styles.num_wrap}>
                <div className={styles.num_span}>{homeStatics?.creators}</div>
                <div className={styles.num_title_wrap}>{t("Creators")}</div>
              </div>
              <div className={styles.num_wrap}>
                <div className={styles.num_span}>{homeStatics?.artWorks}</div>
                <div className={styles.num_title_wrap}>{t("Artworks")}</div>
              </div>
            </div>
          </div>
          <div className={styles.right_wrapper}>
            {/* <Swiper*/}
            {/*  effect={'cards'}*/}
            {/*  grabCursor={true}*/}
            {/*  modules={[EffectCards]}*/}
            {/*  className={styles.home_swiper}*/}
            {/*  autoplay={true}*/}

            {/* >*/}
            {/*  {*/}
            {/*    homeBanner.map(item=>{*/}
            {/*      return <SwiperSlide className={styles.swiper_item}>*/}
            {/*        <img*/}
            {/*          src={item?.images?.split("|")[0]}/>*/}
            {/*      </SwiperSlide>;*/}
            {/*    })*/}
            {/*  }*/}
            {/* </Swiper>*/}


            {
              // <Slider className={styles.home_swiper} {...{
              //   dots: false,
              //   infinite: true,
              //   arrows:false,
              //   speed: 500,
              //   slidesToShow: 1,
              //   slidesToScroll: 1,
              //   autoplay:true,
              //   fade: true,
              //   cssEase: 'linear',
              //   autoplaySpeed: 2000,
              // }}>
              //
              //   {
              //     // homeBanner.map((item, index)=>{
              //     //   return <div key={item.id} className={styles.swiper_item}>
              //     //     <img
              //     //       className={styles.background_img}
              //     //       src={item?.background}/>
              //     //     <img onClick={()=>toKOLUser(item)} className={styles.head_pic} src={item?.headPic} />
              //     //     <div className={styles.author_des_wrap}>
              //     //       <div className={styles.nick_name}>{item.nickName}</div>
              //     //       <span className={styles.introduction}>{item.introduce}</span>
              //     //       <div className={styles.follow_info_wrapper}>
              //     //         <div className={styles.follow_info_item}><span>{numFormat(item.postCount)}</span> {t("imomoPersonPostNum")}</div>
              //     //         <div className={styles.follow_info_item}><span>{numFormat(item.followers)}</span> {t("imomoPersonFollowers")}</div>
              //     //         <div className={styles.follow_info_item}><span>{numFormat(item.followering)}</span> {t("imomoPersonFollowing")}</div>
              //     //       </div>
              //     //     </div>
              //     //   </div>;
              //     // })
              //   }
              //
              // </Slider>
            }

            {
            //   <Carousel dots={false} autoplay className={styles.home_swiper} effect={"fade"}>
            //     {
            //       homeBanner.map((item, index)=>{
            //         return <div key={item.id} className={styles.swiper_item}>
            //           <div
            //             className={styles.background_img}
            //             style={{background:`url(${item?.background ? '/home_background_new.png' : item?.background}) center top / cover no-repeat`}}
            //             />
            //           {/* <img onClick={()=>toKOLUser(item)} className={styles.head_pic} src={item?.headPic} />*/}
            //           <img onClick={()=>toKOLUser(item)} className={styles.head_pic} src={defaultHeadPic} />
            //           <div className={styles.author_des_wrap}>
            //             <div className={styles.nick_name}>{item.nickName}</div>
            //             <span className={styles.introduction}>{item.introduce}</span>
            //             <div className={styles.follow_info_wrapper}>
            //               <div className={styles.follow_info_item}><span>{numFormat(item.postCount)}</span> {t("imomoPersonPostNum")}</div>
            //               <div className={styles.follow_info_item}><span>{numFormat(item.followers)}</span> {t("imomoPersonFollowers")}</div>
            //               <div className={styles.follow_info_item}><span>{numFormat(item.followering)}</span> {t("imomoPersonFollowing")}</div>
            //             </div>
            //           </div>
            //         </div>;
            //       })
            //     }
            // </Carousel>
            }

          </div>
        </div>
      </div>
    </Layout>
  </>;
};


export async function getServerSideProps({ req, res, locale }) {
  res.setHeader(
    'Cache-Control',
    'public, s-maxage=10, stale-while-revalidate=59'
  );

  let  myRegions = locale;
  let allCookie = req.headers.cookie;
  let token =  getCookieByString('token', allCookie);
  let other = {
    regions: myRegions,
    token,
  };
  let homeStatics;
  try {
    homeStatics = await APIGetHomePageStatics({}, other);
    // homeStatics = {
    //   result:{
    //     creators:0,
    //     artWorks:0
    //   }
    // };
  } catch (e){
    homeStatics = {
      result:{
        creators:0,
        artWorks:0
      }
    };
  }

  let homeBanner = {
    result:{
      userRecommended:[]
    }
  };
  try {
    homeBanner = await APIGetHomePageBanner({}, other);
    console.log(homeBanner, "homeBanner")
    console.log(homeBanner.result.userRecommended, "userRecommended")
  } catch (e){
    homeBanner.result.userRecommended = [];
    console.log(homeBanner, "homeBanner Err")
  }


  return {
    props: {
      homeStatics: homeStatics && homeStatics.result,
      homeBanner:homeBanner.result.userRecommended
    }
  };
}

export default Home;
