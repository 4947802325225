import React, {useState, useEffect} from 'react';
import {
  Layout,
  Progress,
  Skeleton,
  message,
  Avatar,
  Select, Spin
} from 'antd';
import {
  APIScreenShotsGiveLike,
  APIScreenShotsGetList, APIImgCardEmojiClick
} from '../../api';
import {useRouter} from "next/router";
import styles from './imgCard.module.scss';
import {defaultHeadPic, numFormat} from '../../tools/action';
import defaultAvatarSrc1 from "@/imgs/defaultAvatar/1.svg";
import defaultAvatarSrc2 from "@/imgs/defaultAvatar/2.svg";
import defaultAvatarSrc3 from "@/imgs/defaultAvatar/3.svg";
import defaultAvatarSrc4 from "@/imgs/defaultAvatar/4.svg";
import {coinsNoticeActionNew} from "@/tools/action";
import OssImg from "@/components/OssImg";
import Img from '@/components/Img';
import useDispatchAction from '@/hooks/useDisptachAction';
import {setLoginModalShow, setLoginModalHidden, setLoginShowModal} from '@/redux/actions/home';
import {useSelector} from "react-redux";
import {Emoji} from "emoji-mart-virtualized";
import {numFormatNotRound} from "@/tools/numFormat";


const redirectLogin = (func) => {
    // let hrefNow = window.location.href;
    // return window.location.href = "/login?redirect=" + encodeURI(hrefNow);
  func && func();
  return;
};
const cdn = (path) => {
    return path && path.replace(/igamie-web.s3.ap-southeast-1.amazonaws.com/, 'cdn.igamie.com');
};
const isMobile = () => {
    return false;
};

const ImgCard = (props) => {
  props.item.nickName = props.item.nickName === null || props.item.nickName === '' ? props.item.email && props.item.email.toString().split('@')[0] : props.item.nickName;
  const [item, setItem] = useState(props.item);
  const router = useRouter();
  const [showDetails, setShowDetails] = useState(false);
  const [onClick, setOnClick] = useState(false);
  const [isSee, setIsSee] = useState(false);
  const [seeInfo, setSeeInfo] = useState(false);
  const [downLoadLoading, setDownLoadLoading] = useState(false);
  const [emojiAddWidth, setEmojiAddWidth] = useState(false);
  const [showEmojiList, setShowEmojiList] = useState([]);
  const userInfo = useSelector(state => state.userInfo.userInfo.userInfo);

  const [emojiMapNum, setEmojiMapNum] = useState({
    "Thumbs Up": 0,
    "Face Blowing a Kiss": 0,
    "Face with Tears of Joy": 0,
    "Drooling Face": 0,
    "Smiling Face with Horns": 0,
    "Face Vomiting": 0
  });
  const [emojiMapIsClick, setEmojiMapIsClick] = useState({
    "Thumbs Up": 0,
    "Face Blowing a Kiss": 0,
    "Face with Tears of Joy": 0,
    "Drooling Face": 0,
    "Smiling Face with Horns": 0,
    "Face Vomiting": 0
  });

  let emojiId = {
    "Thumbs Up": {
      sort: 1,
      id: '+1'
    },
    "Face Blowing a Kiss": {
      sort: 2,
      id: 'kissing_heart'
    },
    "Face with Tears of Joy": {
      sort: 3,
      id: 'joy'
    },
    "Drooling Face": {
      sort: 4,
      id: 'drooling_face'
    },
    "Smiling Face with Horns": {
      sort:5,
      id: 'smiling_imp'
    },
    "Face Vomiting": {
      sort: 6,
      id: 'face_vomiting'
    }
  };

  const toUserDetails = (e) =>{
    e.stopPropagation();
    router.push('/personal-center/[userId]', `/personal-center/${item.userId}`,  {locale: router.locale} );
  };
  const dispatchAction = useDispatchAction({setLoginModalHidden, setLoginShowModal});


  const star = (e) => {
    e.stopPropagation();   // 阻止冒泡事件

    // if (localStorage.getItem('token') === null) {
    //   return redirectLogin();
    // }

    const token = localStorage.getItem("token");
    if (token === null || token === "") {
      // return redirectLogin();
      return redirectLogin(()=>dispatchAction.setLoginShowModal(true));
    }


    if (item.isLike === 1) {
      setItem({
        ...item,
        isLike: 0,
        likes: item.likes - 1,
      });
    } else {
      setOnClick(true);
      setItem({
        ...item,
        isLike: 1,
        likes: item.likes + 1,
        view: onClick ? item.view : item.view + 1
      });
    }
    APIScreenShotsGiveLike(JSON.stringify({
      type: 1,
      screenshotId: props.item.id
    })).then(resp => {
      if (resp.resultCode === 10006 || resp.resultCode === 10004 || resp.resultCode === 10007 || resp.resultCode === 10005) {
        redirectLogin(()=>dispatchAction.setLoginShowModal(true));
      }
      if (resp.result){
        coinsNoticeActionNew();
      }
    }).catch(err => {
      message.error('connect err');
    });
  };
  const toDetails = () => {
    props.onShowDetails?.(true);
    // setShowDetails(true);
  };
  const onSeeClick = (e) => {
    e.stopPropagation();
    setIsSee(true);
  };
  const onCloseSeeClick = (e) => {
    e.stopPropagation();
    setIsSee(false);
  }
  const onSeeInfoClick = (e) => {
    e.stopPropagation();
    props.onShowSettingInfo?.(props.item.imageId);
  };
  useEffect(() => {
    const screenShotId = localStorage.getItem('screenShotId');
    const deLikes = localStorage.getItem('likes');
    const deIsLike = localStorage.getItem('isLike');
    const deView = localStorage.getItem('view');
    setSeeInfo(props.item.isIncludeImageSets === 1);
    if (screenShotId !== null && parseInt(screenShotId) === item.id && deLikes !== null && deIsLike !== null && deView !== null) {
      return setItem({
        ...item,
        isLike: parseInt(deIsLike),
        likes: parseInt(deLikes),
        view: parseInt(deView)
      });
    }
    let emojiMap = {
      ...emojiMapNum,
    };
    if (props.item.emojiss) {
      let emojiMap = {
        ...emojiMapNum,
        ...props.item.emojiss
      }
      setEmojiMapNum({
        ...emojiMapNum,
        ...props.item.emojiss
      });
      let showList = []
      for (let key in emojiMap) {
        if (emojiMap[key] >= 1){
          let item = {
            name: key,
            value: emojiMap[key],
            emojiId: emojiId[key].id,
            sort: emojiId[key].sort,
          };
          showList.push(item);
        }
      }
      showList.sort((a, b) => b.value - a.value)
      setShowEmojiList(showList)
    }

    // else {
    //   let showList = []
    //   for (let key in emojiMap) {
    //     let item = {
    //       name: key,
    //       value: emojiMap[key],
    //       emojiId: emojiId[key].id,
    //       sort: emojiId[key].sort,
    //     }
    //     showList.push(item);
    //   }
    //   showList.sort((a, b) => b.value - a.value)
    //   setShowEmojiList(showList)
    // }

    if (props.item.isFire) {
      setEmojiMapIsClick({
        ...emojiMapIsClick,
        ...props.item.isFire
      })
    }
  }, []);

  useEffect(() => {
    setIsSee(userInfo.isBlurImage === 0 && props.item.isNsfw === 1);
  }, [userInfo]);

  const [imgLoad, setImgLoad] = useState(true);
  const loadSuccess = () => {
    setImgLoad(false);
  };

  const loadError = () => {
    setItem({
      ...item,
      headPic: '/2021-08-19/c62bfa34-9941-41f3-81d0-3733038a09d5.png'
    });
    setImgLoad(false);
  };

  const onDownloadClick = (e) => {
    e.stopPropagation();
    if (item.images && item.images !== '') {
      setDownLoadLoading(true);
      fetch(item.images + '?t=' + new Date().getTime(), {
      }).then(r => r.blob()).then(res => {
        console.log(res);
        const blob = new Blob([res]);
        const objectURL = URL.createObjectURL(blob);
        let link = document.createElement('a');
        link.download = item.title + `.png`;
        link.href = objectURL;
        link.click();
        URL.revokeObjectURL(objectURL);
      }).finally(() => {
        setDownLoadLoading(false);
      });
    }
  };

  const loadAvatar = (src) => {
    const defaultAvatarList = [defaultAvatarSrc1, defaultAvatarSrc2, defaultAvatarSrc3, defaultAvatarSrc4];
    const defaultIndex = Math.floor(Math.random() * 4);
    return (
      src ?
        cdn(src) :
        <img src={defaultAvatarList[defaultIndex].src} />
    );
  };

  const back = () => {
    setShowDetails((data) => {
      const newData = !data;
      return newData;
    });
  };

  const onEmojiClick = (e, name) => {
    e.stopPropagation();
    let emojiNum = emojiMapNum;
    let emojiClick = emojiMapIsClick
    console.log("emojiClick:", name)
    console.log("emojiClick:", emojiMapIsClick)
    if (emojiMapIsClick[name] === 1) {
      emojiNum[name] = emojiNum[name] - 1;
      emojiClick[name] = 0
    } else {
      emojiNum[name] = emojiNum[name] + 1;
      emojiClick[name] = 1
    }
    setEmojiMapNum({
      ...emojiNum
    });
    setEmojiMapIsClick({
      ...emojiClick
    })


    APIImgCardEmojiClick(JSON.stringify({
      screenshotId: props.item.id,
      emojiType: name
    })).then(resp => {
      if (resp.resultCode === 200) {
       // message.success()
      }
    });
  };

  const onAddIconClick = (e) => {
    e.stopPropagation()
    if (emojiAddWidth) {
      let showList = []
      for (let key in emojiMapNum) {
        if (emojiMapNum[key] >= 1){
          let item = {
            name: key,
            value: emojiMapNum[key],
            emojiId: emojiId[key].id,
            sort: emojiId[key].sort,
          }
          showList.push(item);
        }
      }
      showList.sort((a, b) => b.value - a.value)
      setShowEmojiList(showList)
    } else {
      let showList = []
      for (let key in emojiMapNum) {
        let item = {
          name: key,
          value: emojiMapNum[key],
          emojiId: emojiId[key].id,
          sort: emojiId[key].sort,
        }
        showList.push(item);
      }
      showList.sort((a, b) => a.sort - b.sort)
      setShowEmojiList(showList)
    }
    setEmojiAddWidth(!emojiAddWidth)
  };
  return (userInfo.isShowImageContent === 1 || props.item.isNsfw !== 1 || props.isSelf) ? (
    <div onClick={() => isSee || props.item.isNsfw !== 1 ? toDetails() : null} className={styles.item}>
      <div className={styles.item_img_div}>
        {
          item.images?.split('|').length > 1 && (
            <div className={styles.multiple_wrapper}>
              <div className={styles.multiple_icon}></div>
              <div className={styles.multiple_num}>{item.images?.split('|').length}</div>
            </div>
          )
        }
        {
          (props.item.isNsfw === 1 && !props.hideEye) ? (
            <>
              {
                !isSee && (
                  <div className={styles.vague_div}>NSFW</div>
                )
              }
              {
                (isSee && !props.hideEmoji) && (
                  <div className={`${styles.emoji_wrapper} ${emojiAddWidth && styles.emoji_wrapper_width}`}>
                    <div onClick={onAddIconClick} className={`${styles.add_icon}`}></div>
                    {
                      showEmojiList.map((item, index) => (
                        <div className={styles.emoji_item}>
                          <div onClick={(e) => onEmojiClick(e, item.name)} className={styles.emoji_box}>
                            <Emoji emoji={{ id: item.emojiId, skin: 4 }} set='apple' size={16} />
                          </div>
                          <div className={styles.number}>{numFormatNotRound(emojiMapNum[item.name])}</div>
                        </div>
                      ))
                    }
                  </div>
                )
              }
              <div className={styles.left_up_wrapper}>
                {
                  !isSee ? (
                    <div onClick={onSeeClick} className={styles.eye_icon}></div>
                  ) : (
                    <div onClick={onCloseSeeClick} className={styles.eye_close_icon}></div>
                  )
                }

                {
                  (item.imageId && isSee) && (
                    <div onClick={onSeeInfoClick} className={styles.info_icon}></div>
                  )
                }
              </div>

            </>
          ) : (
            <>
              {/* {*/}
              {/*  (seeInfo) && (*/}
              {/*    <div onClick={onSeeInfoClick} className={styles.info_icon}></div>*/}
              {/*  )*/}
              {/* }*/}
              <div className={styles.left_up_wrapper}>
                {
                  item.imageId && (
                    <div onClick={onSeeInfoClick} className={styles.info_icon}></div>
                  )
                }
              </div>
              {
                !props.hideEmoji && (
                  <div className={`${styles.emoji_wrapper} ${emojiAddWidth && styles.emoji_wrapper_width}`}>
                    <div onClick={onAddIconClick} className={`${styles.add_icon}`}></div>
                    {
                      showEmojiList.map((item, index) => (
                        <div key={index} className={styles.emoji_item}>
                          <div onClick={(e) => onEmojiClick(e, item.name)} className={styles.emoji_box}>
                            <Emoji emoji={{ id: item.emojiId, skin: 4 }} set='apple' size={16} />
                          </div>
                          <div className={styles.number}>{numFormatNotRound(emojiMapNum[item.name])}</div>
                        </div>
                      ))
                    }
                  </div>
                )
              }

            </>
          )
        }
        {
          item.isVideo === 0 ? imgLoad ?
            <Skeleton.Button className={styles.skeleton} active={true}></Skeleton.Button> : null : null
        }
        {
          item.isVideo === 0 ?
            <Img
              className={`${styles.item_img}`}
              src={item.images?.split('|')[0]} alt={item.title && item.title} />
            :
            item.images?.split('|').length === 2 ?
              <OssImg
                width={945}
                quality={99}
                height={1282}
                className={`${styles.item_img}`}
                src={cdn(item.images?.split('|')[1])} alt={item.title && item.title}
              />
              :
              <video
                className={styles.item_img}
                 src={item.images?.split("|")[0]} alt={item.title && item.title}
              />
        }
      </div>
      {/* <div*/}
      {/*  style={item.bestState === 0 && item.tags === '' ? {display: 'none'} : item.bestState === 0 && item.tags !== 'Hot deal' ? {display: 'none'} : {}}*/}
      {/*  className={item.bestState === 1 ? styles.week_best : styles.hot_deal}>*/}
      {/*  {item.bestState === 1 ? 'Weekly Best' : item.tags === 'Hot deal' ? 'Hot Deal' : ''}*/}
      {/* </div>*/}

      <div className={styles.post_info}>
        {/* <div className={styles.item_top_wrapper}>*/}
        {/*  <div className={styles.item_title}>*/}
        {/*    {item.title}*/}
        {/*  </div>*/}
        {/*  <Spin className={'imomo-spin'} spinning={downLoadLoading}>*/}
        {/*    <div onClick={onDownloadClick} className={styles.download_icon}></div>*/}
        {/*  </Spin>*/}

        {/* </div>*/}
        <div className={styles.item_userInfo}>
          {/* <div className={styles.share_div}>*/}
          {/*  <div className={styles.shareIcon}/>*/}
          {/*  <div>{numFormat(item.view)}</div>*/}
          {/* </div>*/}
          <div className={styles.left_wrapper}>
            <div className={styles.userAvatar} onClick={(e)=>toUserDetails(e)}>
              {/* <OssImg src={item.headPic} width={60} height={60} />*/}
              <OssImg src={defaultHeadPic} width={60} height={60} />
            </div>
            <div className={styles.item_title}>
              <span className={styles.title}>{item.title}</span>
              <span className={styles.user_name}>{item.nickName}</span>
            </div>
          </div>
          <div className={styles.right_wrapper}>
            {/* <div className={styles.star_div}>*/}
            {/*  /!* <div>{numFormat(item.likes)}</div>*!/*/}
            {/*  <div id={'star' + item.id} onClick={e => star(e)}*/}
            {/*       className={item.isLike === 1 ? styles.star : styles.giveStar}></div>*/}
            {/* </div>*/}
            <div onClick={(e) => props?.onSendTipClick?.(e, item)} className={styles.send_tip_icon}></div>
          </div>
        </div>
      </div>
      <div className={styles.item_video_play}
           style={item.isVideo === 1 ? {} : {display: 'none'}}></div>
    </div>
  ) : null;
};


export default ImgCard;
