import React, {useEffect, useState} from "react";
import {message, Modal, Spin} from "antd";
import styles from "./index.module.scss";
import {APIGetAIDetailsImg} from "@/api";
import {useRouter} from "next/router";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";

const GenerateInfoModal = ({imageId, close}) => {

  const moduleMap = useSelector(state => state.home.setModuleMap && state.home.setModuleMap.map);


  const router = useRouter();

  let { t, i18n} = useTranslation();

  const [aiInfo, setAIInfo] = useState({
    prompt: '',
    negativePrompt: '',
    modelId: '',
    width: '',
    height: '',
    seed: '',
    scheduler: '',
    numInferenceSteps: '',
    guidanceScale: '',
    multiLingual: '',
    selfAttention: '',
    textIds: [],
    loraModelId: [],
  });

  const [loading, setLoading] = useState(false);

  const schedulerMap = {
    'DPM++ 2M Karras': 'DPM++ 2M Karras',
    'DDPMScheduler': 'DDPM',
    'DDPM': 'DDPM',
    'DDIMScheduler': 'DDIM',
    'DDIM': 'DDIM',
    'PNDMScheduler': 'PNDM',
    'LMSDiscreteScheduler': 'LMS',
    'EulerDiscreteScheduler': 'Euler',
    'EulerAncestralDiscreteScheduler': 'Euler Ancestral',
    'DPMSolverMultistepScheduler': 'DPM Solver Multistep',
    'HeunDiscreteScheduler': 'Heun',
    'KDPM2DiscreteScheduler': 'KDPM2',
    'DPMSolverSinglestepScheduler': 'DPM Solver Singlestep',
    'KDPM2AncestralDiscreteScheduler': 'KDPM2 Ancestral',
    'UniPCMultistepScheduler': 'UniPC Multistep',
    'DDIMInverseScheduler': 'DDIM Inverse',
    'DEISMultistepScheduler': 'DEIS Multistep',
    'IPNDMScheduler': 'IPNDM',
    'KarrasVeScheduler': 'KarrasVe',
    'ScoreSdeVeScheduler': 'ScoreSdeVe',
    'DPM++ 2M': 'DPM++ 2M',
    'DPM++ 2M SDE': 'DPM++ 2M SDE',
    'DPM++ 2M SDE Karras': 'DPM++ 2M SDE Karras',
    'DPM++ 2S a': 'DPM++ 2S a',
    'DPM++ 2S a Karras': 'DPM++ 2S a Karras',
    'DPM++ SDE': 'DPM++ SDE',
    'DPM++ SDE Karras': 'DPM++ SDE Karras',
    'DPM2 a': 'DPM2 a',
    'DPM2 a Karras': 'DPM2 a Karras',
    'DPM2': 'DPM2',
    'DPM2 Karras': 'DPM2 Karras',
    'Euler': 'Euler',
    'Euler a': 'Euler a',
    'Heun': 'Heun',
    'LMS': 'LMS',
    'LMS Karras': 'LMS Karras',
    'PNDM': 'PNDM',
    'Uni PC': 'Uni PC',
  };


  const getDetails = () => {
    setLoading(true);
    APIGetAIDetailsImg({imgId: imageId}).then(resp => {
      if (resp.resultCode === 200) {
        setAIInfo({
          ...resp.result
        });
      }
    }).finally(() => {
      setLoading(false);
    });
  };

  const onGotoGenerate = () => {
    router.push({
      pathname: '/generate',
      query: {...aiInfo}
    });
  };

  const onClose = () => {
    close?.();
  };

  const onCopyClick = () => {
    var oInput = document.createElement('input');
    oInput.value = aiInfo.prompt;
    document.body.appendChild(oInput);
    oInput.select(); // 选择对象
    document.execCommand('Copy');// 执行浏览器复制命令
    oInput.className = 'oInput';
    oInput.style.display = 'none';
    document.body.removeChild(oInput);
    message.success('Prompt copied');
  };

  useEffect(() => {
    getDetails();
  }, []);

  return (

  <div className={styles.box}>
    <Spin spinning={loading} className={"imomo-spin"}>
      <div className={styles.page}>
        <div className={styles.head_wrapper}>
          <div className={styles.title}></div>
          <div onClick={onClose} className={styles.close_icon}></div>
        </div>
        <div className={styles.title}>{t('infoModalPrompt')}</div>
        <div className={styles.content_box}>
          {aiInfo.prompt}
        </div>
        <div className={styles.title}>{t("infoModalNegativePrompt")}</div>
        <div className={styles.content_box}>
          {aiInfo.negativePrompt}
        </div>
        <div className={styles.label}>
          <div className={styles.sub_title}>{t("infoModalModel")}:</div>
          <div className={styles.value}>{moduleMap[aiInfo.modelId] ?? aiInfo.modelId}</div>
        </div>
        <div className={styles.label}>
          <div className={styles.sub_title}>{t("infoModalScheduler")}:</div>
          <div className={styles.value}>{schedulerMap[aiInfo.scheduler] ?? aiInfo.scheduler}</div>
        </div>
        <div style={{display: 'flex', justifyContent: 'space-between'}}>
          <div className={styles.label}>
            <div className={styles.sub_title}>{t("infoModalSteps")}:</div>
            <div className={styles.value}>{aiInfo.numInferenceSteps}</div>
          </div>
          <div className={styles.label}>
            <div className={styles.sub_title}>{t("infoModalScale")}:</div>
            <div className={styles.value}>{aiInfo.guidanceScale}</div>
          </div>
        </div>
        <div className={styles.label}>
          <div className={styles.sub_title}>{t("infoModalSeed")}:</div>
          <div className={styles.value}>{aiInfo.seed}</div>
        </div>
        <div className={styles.action_wrapper}>
          <div onClick={onCopyClick} className={styles.copy_icon}></div>
          <div onClick={onGotoGenerate} className={styles.use_btn}>
            {t("infoModalUseTheseSettings")}
          </div>
        </div>
      </div>
    </Spin>
  </div>
  );
};

export default GenerateInfoModal;
