import React, {Component, useEffect, useRef, useState} from 'react';
import {
  Layout,
  Progress,
  Skeleton,
  message,
  Select,
  Menu,
  Modal, Input, Collapse, Avatar, Form, InputNumber, Button, Tabs
} from 'antd';
import {
  APIScreenShotsGetList,
  APINftProductListC2c,
  APISearchNew,
  APIGetGenerateModelList,
  APIGetCreatorList,
  APIGetCreatorAndPostList,
  APIDiscoverRewards, APIGetAndroidDownloadLink, APIScreenShotsGetNewHomeList
} from '../../api';
import Masonry from 'react-masonry-css'
import ColorThief from '../../tools/ColorThief';
import {gotoPostDetails, isMobile, numFormat, getScrollHeight, getScrollTop, getWindowHeight} from '../../tools/action';
import styles from './gamiezone.module.scss';
import {useRouter} from "next/router";
import { APIGetTagListGamieZone, APIGetTopicList} from '@/api';
import dynamic from 'next/dynamic';
import {useDispatch, useSelector} from "react-redux";
const ActivityCard = dynamic(() => import('@/components/ActivityCard'));
import ImgCard from "@/components/ImgCard";
import ImgCardNft from '@/components/ImgCardNft';
import Img from '@/components/Img'
import {NextSeo} from "next-seo";
import { setNftSellHidden, setNftSellShow } from '@/redux/actions/home'
import useDispatchAction from "@/hooks/useDisptachAction";
import GamezoneCard from "@/components/GamezoneCard";
import MobileBottomSortSelect from "@/components/MobileBottomSortSelect";
import GenerateInfoModal from "@/components/GenerateInfoModal";
import {useTranslation} from "react-i18next";
import DiscoverSearch from "@/components/DiscoverSearch";
import {numFormatNotRound} from "@/tools/numFormat";


const headBase = process.env.NODE_ENV === 'production' ?
  'https://cdn.igamie.com/real' :
  'https://igamie-web.s3.ap-southeast-1.amazonaws.com/dev';


const BannerCard = (props) => {
  const colorCheck = new ColorThief();
  const [color, setColor] = useState([]);
  const [imgLoad, setImgLoad] = useState(true);

  const style = {
    background: 'rgba(' + color + ',0.97)'
  };

  const getColor = () => {
    colorCheck.getColorAsync(props.item.campaignImage).then(data => {
      setColor(data);
      setImgLoad(false);
    }).finally(() => {
      setImgLoad(false);
    });
    setImgLoad(false);
  };
  return (
    <div onClick={() => props.toGameDetails(props.item)} key={props.item.id} className={styles.item_banner}>
      <div className={styles.item_img_div_banner}>
        {
          imgLoad ? <Skeleton.Button className={styles.skeleton} active={true}></Skeleton.Button> : null
        }
        <img onLoad={getColor} className={`${styles.item_img_banner} opa1`} src={props.item.campaignImage}/>
        <div style={imgLoad ? {display: 'none'} : {}} className={styles.item_img_banner_title_div}>
        </div>
        <div style={imgLoad ? {display: 'none'} : {}} className={styles.item_img_banner_title}>
          <p style={{fontSize: '19px', margin: "0 10px"}}>{props.item.campagnName}</p>
        </div>
      </div>
    </div>
  );
};


const TabBar = (props) => {
  const [imgLoad, setImgLoad] = useState(true);
  const loadSuccess = () => {
    setImgLoad(false);
  };
  return (
    <div key={props.item.id} onClick={() => props.onTap(props.item)} className={styles.tabBar}>
      <div className={styles.img_div}
           style={props.checkGame === props.item.id ?
             {
               border: '2px solid #F26E4C',
               boxShadow: '0px 16px 33px -11px rgba(242, 110, 76, 0.3)'
             } :
             {}}>
        <Img className={props.checkGame === null ? styles.img : props.checkGame === props.item.id ? styles.img : styles.img_fail}
         src={`https://cdn.igamie.com/real${props.item.logo}`}  width={190} height={80}
             alt={props.item.name} />

        <Img className={props.checkGame === null ? styles.img_mobile : props.checkGame === props.item.id ? styles.img_mobile : styles.img_fail_m}
        src={`https://cdn.igamie.com/real${props.item.logo}`}  width={190} height={80}
             alt={props.item.name} />
      </div>
      <div style={!imgLoad ? {} : {display: 'none'}} className={styles.game_name}>
        <span>{props.item.name}</span>
      </div>
    </div>
  );
};


const GamieZone = () => {
  const router = useRouter();
  let { t, i18n} = useTranslation();
  const [IsMobile, setIsMobile] = useState(false);
  const hideAppDownload = useSelector(state => state.home.hideAppDownload.hideAppDownload);
  const userInfo = useSelector(state => state.userInfo.userInfo.userInfo);
  const collapsed = useSelector(state => state.home.collapsed && state.home.collapsed.open)
  const igmBalance = useSelector(state => state.home.setIGMBalance && state.home.setIGMBalance.igmBalance);

  // 打赏from
  const [rewardFrom] = Form.useForm();

  const [gamesTab, setGamesTab] = useState({
    checkGame:null,
    tabBarList: [],
  })
  const [topicList, setTopList] = useState([])
  const [state, setState] = useState({
    pageNum:1,
    source:[],
    pageSize:12,
    itemDetail: {},
    showShare: false,
    isLogin: false,
    percent: 0,
    upLoadImg: false,
    loadAll: false,
  })
  const [sourceList, setSourceList] = useState([])
  const [pageLoading, setPageLoading] = useState(false)
  const [selectType, setSelectType] = useState(1) // 1为nft zone 2为discover
  const [nftProductSortType, setNftProductSortType] = useState(0)
  const [isLogin, setIsLogin] = useState(false)
  const nftSellModalVis = useSelector(state => state.home.nftsellmodal.show)
  const dispatchAction = useDispatchAction({ setNftSellHidden, setNftSellShow })
  const [searchText, setSearchText] = useState("");
  const [modelTagList, setModelTagList] = useState([]);
  const [modelTagIndex, setModelTagIndex] = useState('all');
  const modelTagIndexRef = useRef('all');
  const pageNum = useRef(1)
  const pageList = useRef([])
  const pageSort = useRef(0)
  const pageType = useRef(2)

  const [imageId, setImageId] = useState(null);
  const [aiInfoVis, setAIInfoVis] = useState(false);

  const [pageRefresh, setPageRefresh] = useState(false)
  const [showSelectWrap, setShowSelectWrap] = useState(false);
  const [sortBy, setSortBy] = useState(0);   // 参考 sortMap
  const [hotTabIndex, setHotTabIndex] = useState(0);

  const [sortBySelectIndex, setSortBySelectIndex] = useState(0);

  const [creators, setCreators] = useState([]);
  const [hotPosts, setHotPosts] = useState([]);

  const breakpointColumnsObj = {
    default: 5,
    1500:5,
    1100: 4,
    700: 3,
    500: 2
  };

  const searchRef = useRef(null);

  const mobileSortClick = (key)=>{
    setSortBy(key);
    key = Number(key)
    if (key === nftProductSortType) return
    setNftProductSortType(key)
    pageNum.current = 1
    pageList.current = []
    pageSort.current = key
    setPageRefresh(!pageRefresh)

  };

  const [shotItem, setShotItem] = useState({});

  const [detailVis, setDetailVis] = useState(false);

  const [gameName, setGameName] = useState('');

  const [isNFTShop, setIsNFTShop] = useState(false);

  const [creatorInteraction, setCreatorInteraction] = useState({
    left: false,
    right: true,
  });

  const [hotPostInteraction, setHotPostInteraction] = useState({
    left: false,
    right: true,
  })

  const userIsLogin = useRef(null);

  const modelTagListRef = useRef(null);

  const hotUserListRef = useRef(null);

  const hotPostListRef = useRef(null);

  // 打赏请求加载
  const [rewardLoading, setRewardLoading] = useState(false);
  const [rewardUserInfo, setRewardUserInfo] = useState({});
  // 打赏弹窗
  const [rewardVis, setRewardVis] = useState(false);

  // 提交打赏
  const onRewardFinish = (value) => {
    setRewardLoading(true);
    const query = {
      ...value,
      subjectId: rewardUserInfo.id,
      type: 0,
    }
    APIDiscoverRewards(JSON.stringify(query)).then(resp => {
      if (resp.result) {
        message.success(resp.resultMessage)
        setRewardVis(false)
      } else {
        message.error(resp.resultMessage)
      }
    }).finally(() => {
      setRewardLoading(false)
    })
  };

  const toDetails = (item) => {
    let gameName = (item.tags === undefined || item.tags === null || item.tags.length < 1) ? "hotTag" : item.tags.replace(/\s+/g, "_").replace(/\|+/g, "_").replace(/#/g, "");
    // item.id = 6893142757482048;
    gotoPostDetails(router, item);
    // return;
    // if (IsMobile) {
    //   gotoPostDetails(router, item);
    //   return;
    // }
    // setGameName(gameName);
    // setShotItem(item);
    // setIsNFTShop(false);
    // setDetailVis(true);
  };

  const toGameDetails = (item) =>{
    if (IsMobile) {
      router.push('/discover/[gamename]', `/discover/${item.name.replace(/ /g, "_")}`,  {locale: router.locale} );
      return;
    }
    setDetailVis(true);
  };

  const toNftDetails = (item) => {
    console.log('item....', item)
    if (IsMobile) {
      router.push(`/nftzone/${item.id}`, '', {locale: router.locale} );
      return;
    }
    setDetailVis(true);
    setIsNFTShop(true);
    setGameName(gameName);
    setShotItem(item);
  }

  // 获取game list
  const getGameList = () => {
    if (gamesTab.tabBarList.length > 0) {
      return
    }
    APIGetTagListGamieZone().then(resp => {
      let list = resp.result.records ? resp.result.records : [];
      list.forEach(item =>{
        item.banner = "[\"https://cdn.igamie.com/dev/2021-10-19/FDxcwlrthgjV.jpg\",\"https://cdn.igamie.com/dev/2021-10-19/FDxcwlrthgjV.jpg\"]";
      });
      setGamesTab({
        ...gamesTab,
        tabBarList: list
      })
    })
  }


  const sortClick = (data) => {
    const key = Number(data.key)
    if (key === nftProductSortType) return
    setNftProductSortType(key)
    pageNum.current = 1
    pageList.current = []
    pageSort.current = key
  }

  const gotoDetails = () => {

  }

  const switchTab = (val) => {
    setSelectType(val)
    pageType.current = val
    pageList.current = []
    pageSort.current = 0
    pageNum.current = 1
    setNftProductSortType(0)
  }

  const getTopicList = () => {
    APIGetTopicList(JSON.stringify({
      page_size:4,
      page_num:1
    })).then(resp => {
      setTopList(resp.result.records)
    })
  }

  const showShareModal = (e, item) => {
    e.persist();
    e.stopPropagation();
    setState({
      ...state,
      itemDetail: item,
      showShare: true
    })
  }



  const changePercent = (size) => {
    setState({
      ...state,
      percent: size
    })
  }

  const upLoad = (flag) => {
    if (flag) {
      setState({
        ...state,
        upLoadImg: true
      })
    } else {
      setState({
        ...state,
        upLoadImg: false
      })
    }
  }

  const reGetData = (item) => {

  }

  const bindHandleScroll = () => {
    if (getScrollHeight() - getScrollTop() - getWindowHeight() < 380) {
      window.removeEventListener('scroll', bindHandleScroll, false);
      getZonePost({
        list: pageList.current,
        pageNum: pageNum.current
      })
    }
  }

  const getNftSellPost = (params) => {
    console.log('这次请求', params)
    let resList;
    window.removeEventListener('scroll', bindHandleScroll, false)
    APINftProductListC2c({
      sortType: params.sort,
      page_size: 12,
      page_num: params.pageNum
    }).then(resp => {
      if (resp.result) {
        if (resp.result.records && resp.result.records.length > 0) {
          if (params.list) {
            resList = params.list.concat(resp.result.records)
          } else {
            resList = resp.result.records
          }
          setSourceList(resList)
          pageList.current = resList
          pageSort.current = params.sort
          if (resp.result.records.length === 12) {
            window.addEventListener('scroll', bindHandleScroll, false)
            pageNum.current = pageNum.current + 1;
          } else {
            window.removeEventListener('scroll', bindHandleScroll, false)
          }
        }
        // if (resp.result.records && !resp.result.records.length  && params.list) {
        //   setSourceList([])
        //   pageList.current = []
        //   pageSort.current = params.sort
        // }
      }
    })
  }

  const searchChange = (text) => {
    console.log('search')
    setSearchText(text);
    searchRef.current = text;
  };

  const getZonePost = (params, pageSize = 20) => {
    let resList;
    window.removeEventListener('scroll', bindHandleScroll, false);
    console.log("search zonpost", modelTagListRef.current);
    if (searchRef.current && searchRef.current !== '') {
      APISearchNew({
        page_num: params.pageNum,
        page_size: pageSize,
        keyword: searchRef.current,
        lora: modelTagIndexRef.current === "all" ? null : modelTagIndexRef.current
      }, 'postQuery').then(resp => {
        if (resp.result) {
          if (resp.result.records && resp.result.records.length > 0) {
            if (params.list) {
              resList = params.list.concat(resp.result.records).map(item => {
                return {
                  searchValue: true,
                  screenshotRsp: item
                };
              });
            } else {
              resList = resp.result.records.map(item => {
                return {
                  searchValue: true,
                  screenshotRsp: item
                };
              });
            }
            if (userIsLogin.current.id) {
              resList = resList.map(item => {
                if (userIsLogin.current.isShowImageContent === 1) {
                  item.screenshotRsp.showContent = true;
                } else {
                  if (item.screenshotRsp.isNsfw === 1) {
                    item.screenshotRsp.showContent = false;
                  } else {
                    item.screenshotRsp.showContent = true;
                  }
                }
                return item;
              });
            } else {
              resList = resList.map(item => {
                if (item.screenshotRsp.isNsfw === 1) {
                  item.screenshotRsp.showContent = false;
                } else {
                  item.screenshotRsp.showContent = true;
                }
                return item;
              });
            }
            setSourceList(resList)
            pageList.current = resList
            pageSort.current = params.sort
            if (resp.result.records.length === pageSize) {
              window.addEventListener('scroll', bindHandleScroll, false)
              pageNum.current = pageNum.current + 1
            } else {
              window.removeEventListener('scroll', bindHandleScroll, false);
            }
          }
        }
      });
    } else {
      if (sortBySelectIndex === 0) {
        APIScreenShotsGetList(JSON.stringify({
          isIndex:0,
          page_num: params.pageNum,
          page_size: pageSize,
          lora: modelTagIndexRef.current === "all" ? null : modelTagIndexRef.current
        })).then(resp => {
          if (resp.result) {
            if (resp.result.records && resp.result.records.length > 0) {
              if (params.list && params.pageNum !== 1) {
                resList = params.list.concat(resp.result.records);
              } else {
                resList = resp.result.records;
              }
              if (userIsLogin.current.id) {
                console.log('这是登录了的');

                resList = resList.map(item => {
                  if (userIsLogin.current.isShowImageContent === 1) {
                    item.screenshotRsp.showContent = true;
                  } else {
                    if (item.screenshotRsp.isNsfw === 1) {
                      item.screenshotRsp.showContent = false;
                    } else {
                      item.screenshotRsp.showContent = true;
                    }
                  }
                  return item;
                });
              } else {
                console.log('这是没有登录了的');
                resList = resList.map(item => {
                  if (item.screenshotRsp.isNsfw === 1) {
                    item.screenshotRsp.showContent = false;
                  } else {
                    item.screenshotRsp.showContent = true;
                  }
                  return item;
                });
              }
              setSourceList(resList);
              pageList.current = resList;
              pageSort.current = params.sort;
              if (resp.result.records.length === pageSize) {
                window.addEventListener('scroll', bindHandleScroll, false);
                pageNum.current = pageNum.current + 1;
              }
            }
          }
        });
      } else {
        APIScreenShotsGetNewHomeList({
          type:sortBySelectIndex,
          page_num: params.pageNum,
          page_size: pageSize,
        }).then(resp=>{
          if (resp.result) {
            if (resp.result.records && resp.result.records.length > 0) {
              if (params.list && params.pageNum !== 1) {
                resList = params.list.concat(resp.result.records);
              } else {
                resList = resp.result.records;
              }
              if (userIsLogin.current.id) {
                console.log('这是登录了的');

                resList = resList.map(item => {
                  if (userIsLogin.current.isShowImageContent === 1) {
                    item.screenshotRsp.showContent = true;
                  } else {
                    if (item.screenshotRsp.isNsfw === 1) {
                      item.screenshotRsp.showContent = false;
                    } else {
                      item.screenshotRsp.showContent = true;
                    }
                  }
                  return item;
                });
              } else {
                console.log('这是没有登录了的');
                resList = resList.map(item => {
                  if (item.screenshotRsp.isNsfw === 1) {
                    item.screenshotRsp.showContent = false;
                  } else {
                    item.screenshotRsp.showContent = true;
                  }
                  return item;
                });
              }
              setSourceList(resList);
              pageList.current = resList;
              pageSort.current = params.sort;
              if (resp.result.records.length === pageSize) {
                window.addEventListener('scroll', bindHandleScroll, false);
                pageNum.current = pageNum.current + 1;
              }
            }
          }
        });
      }

    }
  };

  const test = () => {
    console.log('test')
    console.log('source', sourceList)
    getNftSellPost({
      list: null,
      sort: 1,
      pageNum: 1
    })
  }
  // 购买成功，弹框消失，列表刷新
  const buySuccessCb = (id) => {
    console.log('buySuccessCb')
    // setDetailVis(false)
    pageType.current = 1
    pageList.current = []
    pageSort.current = 0
    pageNum.current = 1
    setPageRefresh(!pageRefresh)
  }

  const checkLink = () => {
    console.log('路由', router.query)
    const linkQuery = router.query;
    if (linkQuery.zone) {
      if (isMobile()) {
        router.push(`/discover/hotGame/${linkQuery.businessId}`)
        return;
      }
      setIsNFTShop(linkQuery.zone === 'nft');
      setShotItem({id: linkQuery.businessId});
      setDetailVis(true);
    }
  };

  const onModelTagChange = (id) => {
    setModelTagIndex(id);
    modelTagIndexRef.current = id
  };

  const getTagList = () => {
    APIGetGenerateModelList().then(resp => {
      if (resp.resultCode === 200) {
        let key = router.locale === 'ja' ? 'キャラクター' : 'Character';
        let loraModel = resp.result.lara;
        let loraList = [];
        let characterList = loraModel[key];
        if (characterList) {
          loraList = characterList.filter(key => key.type === 1);
        }
        setModelTagList(loraList);
      }
    });
  };

  const onModleLeftClick = () => {
    if ((modelTagListRef.current.scrollLeft - 1000) <= 0) {
      modelTagListRef.current.scrollTo({left: 0, behavior: 'smooth'});
    } else {
      modelTagListRef.current.scrollTo({top: 0, left: modelTagListRef.current.scrollLeft - 1000, behavior: 'smooth'});
    }
  };
  const onModleRightClick = () => {
    console.log(modelTagListRef.current.scrollWidth);
    if (modelTagListRef.current.scrollLeft + 1000 >=  modelTagListRef.current.scrollWidth) {
      modelTagListRef.current.scrollTo({left: modelTagListRef.current.scrollWidth, behavior: 'smooth'});
      // modelListRef.current.scrollLeft = modelListRef.current.scrollWidth;
    }
    modelTagListRef.current.scrollTo({left: modelTagListRef.current.scrollLeft  + 1000, behavior: 'smooth'});
    // modelListRef.current.scrollLeft = modelListRef.current.scrollLeft  + 1000;
  };

  const onHotUserLeftClick = () => {
    if ((hotUserListRef.current.scrollLeft - 1000) <= 0) {
      hotUserListRef.current.scrollTo({left: 0, behavior: 'smooth'});
      setCreatorInteraction({
        left: false,
        right: true,
      });
    } else {
      hotUserListRef.current.scrollTo({top: 0, left: hotUserListRef.current.scrollLeft - 1000, behavior: 'smooth'});
    }
  };
  const onHotUserRightClick = () => {
    if (hotUserListRef.current.scrollLeft + 2000 >=  hotUserListRef.current.scrollWidth) {
      hotUserListRef.current.scrollTo({left: hotUserListRef.current.scrollWidth, behavior: 'smooth'});
      // modelListRef.current.scrollLeft = modelListRef.current.scrollWidth;
    } else {
      hotUserListRef.current.scrollTo({left: hotUserListRef.current.scrollLeft  + 1000, behavior: 'smooth'});
    }
    // modelListRef.current.scrollLeft = modelListRef.current.scrollLeft  + 1000;
  };

  const onHotPostLeftClick = () => {
    if ((hotPostListRef.current.scrollLeft - 1000) <= 0) {
      hotPostListRef.current.scrollTo({left: 0, behavior: 'smooth'});
    } else {
      hotPostListRef.current.scrollTo({top: 0, left: hotPostListRef.current.scrollLeft - 1000, behavior: 'smooth'});
    }
  };
  const onHotPostRightClick = () => {
    console.log(hotPostListRef.current.scrollWidth);
    if (hotPostListRef.current.scrollLeft + 1000 >=  hotPostListRef.current.scrollWidth) {
      hotPostListRef.current.scrollTo({left: hotPostListRef.current.scrollWidth, behavior: 'smooth'});
      // modelListRef.current.scrollLeft = modelListRef.current.scrollWidth;
    }
    hotPostListRef.current.scrollTo({left: hotPostListRef.current.scrollLeft  + 1000, behavior: 'smooth'});
    // modelListRef.current.scrollLeft = modelListRef.current.scrollLeft  + 1000;
  };

  const getCreatorsList = () => {
    APIGetCreatorAndPostList().then(resp=>{
      if (resp.success){
        let hotPostItems = resp.result.screenshotRecommended.filter(key => userInfo.isShowImageContent === 1 || key.isNsfw !== 1);
        console.log('hotst', hotPostItems)
        setHotPosts(hotPostItems);
        setCreators(resp.result.userRecommended);
        if (!isMobile()) {
          if (resp.result.userRecommended && resp.result.userRecommended.length > 0) {
            hotUserListRef.current.addEventListener("scroll", e => {
              const { clientWidth, scrollWidth, scrollLeft } = e.target;
              // const { clientHeight, scrollHeight, scrollTop } = this.scroll;

              const isBottom = scrollLeft + clientWidth + 20 > scrollWidth;
              if (isBottom) {
                setCreatorInteraction({
                  left: true,
                  right: false,
                });
              } else if (scrollLeft === 0) {
                setCreatorInteraction({
                  left: false,
                  right: true,
                });
              } else {
                setCreatorInteraction({
                  left: true,
                  right: true,
                });
              }
            });
          }
          if (resp.result.screenshotRecommended && resp.result.screenshotRecommended.length > 0) {
            hotPostListRef.current.addEventListener("scroll", e => {
              const { clientWidth, scrollWidth, scrollLeft } = e.target;
              // const { clientHeight, scrollHeight, scrollTop } = this.scroll;

              const isBottom = scrollLeft + clientWidth + 20 > scrollWidth;
              if (isBottom) {
                setHotPostInteraction({
                  left: true,
                  right: false,
                });
              } else if (scrollLeft === 0) {
                setHotPostInteraction({
                  left: false,
                  right: true,
                });
              } else {
                setHotPostInteraction({
                  left: true,
                  right: true,
                });
              }
            });
          }
        }

      }
    });
  };



  useEffect(() => {
    getTopicList()
    getGameList()
  }, [])

  useEffect(() => {
    checkLink();
    getTagList()
  }, [router])

  useEffect(() => {
    setIsMobile(isMobile());
  }, []);

  useEffect(() => {
    if (userInfo.id) {
      userIsLogin.current = userInfo;
      if (userInfo.isShowImageContent === 1) {
        const newSourceList = sourceList.map(item => {
          item.screenshotRsp.showContent = true;
          return item;
        });
        setSourceList(newSourceList);
      } else {
        const newSourceList = sourceList.map(item => {
          if (item.screenshotRsp.isNsfw === 1) {
            item.screenshotRsp.showContent = false;
          } else {
            item.screenshotRsp.showContent = true;
          }
          return item;
        });
        setSourceList(newSourceList);
      }
    } else {
      userIsLogin.current = {};
    }
    // getCreatorsList();
  }, [userInfo]);

  useEffect(() => {
    if (!isMobile() || hotTabIndex === 1) {
      pageNum.current = 1;
      setSourceList([]);
      if (searchRef.current && searchRef.current !== '') {
        getZonePost({ pageNum: 1 });
      } else {
        getZonePost({ pageNum: 1 });
        window.addEventListener('scroll', bindHandleScroll, false)
        return () => { window.removeEventListener('scroll', bindHandleScroll, false)  }
      }
    } else {
      getZonePost({ pageNum: 1 });
    }
  }, [searchText, modelTagIndex, hotTabIndex, sortBySelectIndex]);

  useEffect(() => {
    // getCreatorsList(); // 获取热门创造者列表
  }, []);

  const onShowSettingInfo = (imageId) => {
    setImageId(imageId);
    setAIInfoVis(true);
  }

  const onHotShowSettingInfo = (e, imageId) => {
    e.stopPropagation();
    setImageId(imageId);
    setAIInfoVis(true);
  }


  const gotoUserCenter = (id)=> {
    router.push('/personal-center/' + id);
  };

  const onRewardHotPostClick = (e, item) => {
    e.stopPropagation();
    setRewardUserInfo(item);
    setRewardVis(true);
  }

  const onHotTabChange = (index) => {
    setHotTabIndex(parseInt(index));
  };

  const onAndroidDownloadBtnClick = (e) => {
    APIGetAndroidDownloadLink().then(resp => {
      if (resp.success) {
        window.location.href = resp.result.url
      }
    })
  };


  return (
    <>
      <NextSeo
        title={`Discover - imomo`}
        canonical={ router.locale === 'en' ? `${process.env.BASE_URL}${router.asPath} ` : `${process.env.BASE_URL}/${router.locale}${router.asPath}`}
        description={ `imomo discover is the place to get the best gaming content from popular games. Browse top trending posts on their favourite game all on a single platform.` }
        openGraph={{
          type: 'website',
          locale: router.locale,
          url:  router.locale === 'en' ? `${process.env.BASE_URL}${router.asPath} ` : `${process.env.BASE_URL}/${router.locale}${router.asPath}`,
          description: 'imomo discover is the place to get the best gaming content from popular games. Browse top trending posts on their favourite game all on a single platform.',
          siteName: 'imomo',
          images: [
            {
              url: 'https://imomo.ai/favicon.ico',
              width: 1200,
              height: 800,
              alt: 'imomo discover is the place to get the best gaming content from popular games. Browse top trending posts on their favourite game all on a single platform.',
            },
          ],
        }}
        facebook={{
          appId:"2170943866417417"
        }}
        twitter={{
          handle: '@imomo',
          site: '@imomo',
          cardType: 'summary_large_image',
        }}
      />
      <Modal destroyOnClose={true} wrapClassName={'gamie-zone-modal-wrap'} width={843} closable={true} maskClosable={false} footer={null} closeIcon={<></>} centered visible={detailVis} onCancel={() => setDetailVis(false)} className={'gamie-zone-modal'}>
        <GamezoneCard gameName={gameName} shotItem={shotItem} vis={detailVis} buySuccess={ buySuccessCb }   onClose={() => setDetailVis(false)} isNFTShop={isNFTShop} />
      </Modal>
      <Modal destroyOnClose={true} width={520} closable={true} maskClosable={false} footer={null} closeIcon={<></>} wrapClassName={'gamie-zone-modal-wrap'} visible={aiInfoVis} centered className={'gamie-zone-modal'}>
        <GenerateInfoModal imageId={imageId} close={() => setAIInfoVis(false)} />
      </Modal>
      <Layout className={`animated fadeIn animate__fadeInUp ${styles.screenShots_page}`}>
        {
          IsMobile ?
            <div className={`${styles.screenShots_mobile}`}>
              <div className={styles.screenShots_wrapper}>
                <div className={styles.notice_wrapper}>
                  <Collapse
                    className={"imomo-discover-collapse-sty"}
                    expandIconPosition={'right'}
                  >
                    <Collapse.Panel className={"igamie-collapse-head-sty"} key={'1'} header={<h3 style={{margin: 0}} className={styles.label_sty}>{t('imomoDiscoverTitle')}</h3>}>
                      <div className={styles.notice_item_wrapper}>
                        <div className={styles.notice_item}>
                          · {t('imomoDiscoverContent1')}
                        </div>
                        <div className={styles.notice_item}>
                          · {t('imomoDiscoverContent2')}
                        </div>
                        <div className={styles.notice_item}>
                          · {t('imomoDiscoverContent3')}
                        </div>
                        <div className={styles.notice_item}>
                          · {t('imomoDiscoverContent4')}
                        </div>
                      </div>
                      <div className={styles.notice_item_hit}>
                        {t('imomoDiscoverContent5')}
                      </div>
                    </Collapse.Panel>
                  </Collapse>
                </div>

                {
                  creators && creators.length > 0 ? <>
                    <div className={styles.topic_title}>{t("recommendedCreators")}</div>
                    <div className={styles.recommend_creator_wrap}>
                      {
                        creators.map( item => {
                          return <div style={{background: item.background ? `url(${item.background}) center top / cover no-repeat` : ''}} className={styles.creator_item_box}>
                            <div className={styles.creator_item} onClick={()=>gotoUserCenter(item.id)}>
                              <img className={styles.item_avatar} src={item.headPic}></img>
                              <div className={styles.item_userinfo}>
                                <div className={styles.item_username}>{item.nickName}</div>
                                <div className={styles.item_bio}>3453452364536431gddfsafdsaf345243534534dfgdfsa</div>
                                <div className={styles.item_post}>
                                  <span>{numFormatNotRound(item.postCount)} {t('imomoTipBoxPosts')} </span>
                                  <span>{numFormatNotRound(item.followers)} {t('followers1')}</span>
                                </div>
                              </div>
                            </div>
                          </div>;
                        })
                      }
                    </div>
                  </> : null
                }

                {/* <div className={styles.post_tab_wrap}>*/}
                {/*  <div onClick={() => onHotTabChange(0)} className={`${styles.topic_title} ${styles.tab_item} ${hotTabIndex === 0 && styles.tab_item_active}`}>{t('recommended')}</div>*/}
                {/*  <div onClick={() => onHotTabChange(1)} className={`${styles.topic_title} ${styles.tab_item} ${hotTabIndex === 1 && styles.tab_item_active}`}>{t('new')}</div>*/}
                {/* </div>*/}
                <div className={styles.post_tab_wrap}>
                  <Tabs defaultActiveKey={0} tabPosition={'top'} onChange={onHotTabChange}>
                    {
                      // <Tabs.TabPane tab={t('recommended')} key={0}>
                      //   <div id={'scrollDiv'} className={styles.screenShots_content}  style={{minHeight: '1000px'}}>
                      //     <Masonry
                      //       id={`zone-masonry`}
                      //       breakpointCols={breakpointColumnsObj}
                      //       className='my-masonry-grid'
                      //       columnClassName='my-masonry-grid_column'
                      //       columnAttrs={{ className: 'should be overridden', 'data-test': '', style: { '--test': 'test' }}}
                      //     >
                      //       {
                      //         hotPosts && hotPosts.map((item, index) => (
                      //           <div key={item.id}>
                      //             <ImgCard hideEmoji={true} onSendTipClick={onRewardHotPostClick} hideEye={true} onShowSettingInfo={onShowSettingInfo} onShowDetails={() => toDetails(item)} showShare={ (e) => { showShareModal(e, item) } } item={item}/>
                      //           </div>
                      //         ))
                      //       }
                      //     </Masonry>
                      //   </div>
                      // </Tabs.TabPane>
                    }
                    <Tabs.TabPane tab={t('new')} key={0}>
                      <div style={{marginTop: 'unset'}} className={styles.topic_title}>{t("hotTopicsDiscover")}</div>
                      <div className={styles.tag_list_wrapper}>
                        <div ref={modelTagListRef} className={styles.tag_list}>
                          <div onClick={() => onModelTagChange('all')} className={`${styles.tag_item_all} ${modelTagIndex === "all" && styles.tag_item_active}`}>
                            All
                          </div>
                          {
                            modelTagList.map(item => {
                              return (
                                <div onClick={() => onModelTagChange(item.trainingId)} key={item.id} className={`${styles.tag_item} ${modelTagIndex === item.trainingId && styles.tag_item_active}`}>
                                  <img src={item.testImage} />
                                  <div className={styles.model_name_box}>
                                    {router.locale === 'ja' ? item.modelNameJp ?? item.modelName : item.modelName}
                                  </div>
                                </div>
                              )
                            })
                          }
                        </div>
                      </div>
                      <div className={`${styles.select_content}`}>
                        {
                          // <DiscoverSearch onChange={(v) => searchChange(v)} lora={modelTagIndex} />
                        }
                      </div>
                      <Masonry
                        id={`zone-masonry`}
                        breakpointCols={breakpointColumnsObj}
                        className='my-masonry-grid'
                        columnClassName='my-masonry-grid_column'
                        columnAttrs={{ className: 'should be overridden', 'data-test': '', style: { '--test': 'test' }}}
                      >
                        {
                          sourceList && sourceList.map((item, index) => {
                            if (item.screenshotRsp) {
                              return item.screenshotRsp.showContent && (
                                <div key={index}>
                                  <ImgCard onSendTipClick={onRewardHotPostClick} onShowSettingInfo={onShowSettingInfo} onShowDetails={() => toDetails(item.screenshotRsp)} showShare={ (e) => { showShareModal(e, item) } } item={item.screenshotRsp}/>
                                </div>
                              );
                            } else if (item.campaignBannerRsp) {
                              return (
                                <BannerCard toGameDetails={ () => { toGameDetails(item) } } key={index}
                                            item={item.campaignBannerRsp}/>
                              );
                            } else if (item.activityBannerRsp) {
                              return (
                                <ActivityCard isNew={false} isLogin={state.isLogin} changePercent={changePercent} reGetData={reGetData} upLoad={upLoad}
                                              key={index} item={item.activityBannerRsp}/>
                              );
                            }
                          })
                        }
                      </Masonry>
                    </Tabs.TabPane>
                  </Tabs>
                </div>

              </div>
            </div> :
            <div className={ collapsed ? styles.screenShots : `${styles.screenShots} ${styles.screenShots_close}`}>

              <div className={styles.screenShots_wrapper}>
                <div className={styles.notice_wrapper}>
                  <div className={styles.left_content}>
                    <Collapse
                      className={"imomo-discover-collapse-sty"}
                      expandIconPosition={'right'}
                    >
                      <Collapse.Panel className={"igamie-collapse-head-sty"} key={'1'} header={<h3 style={{margin: 0}} className={styles.label_sty}>{t('imomoDiscoverTitle')}</h3>}>
                        <div className={styles.notice_item_wrapper}>
                          <div className={styles.notice_item}>
                            · {t('imomoDiscoverContent1')}
                          </div>
                          <div className={styles.notice_item}>
                            · {t('imomoDiscoverContent2')}
                          </div>
                          <div className={styles.notice_item}>
                            · {t('imomoDiscoverContent3')}
                          </div>
                          <div className={styles.notice_item}>
                            · {t('imomoDiscoverContent4')}
                          </div>
                        </div>
                        <div className={styles.notice_item_hit}>
                          {t('imomoDiscoverContent5')}
                        </div>
                      </Collapse.Panel>
                    </Collapse>
                  </div>
                  {/* <div className={styles.right_content}>*/}
                  {/*  <a target={'_blank'} href={'https://apps.apple.com/sg/app/imomo/id6452010316'}>*/}
                  {/*    <div className={styles.apple_download}></div>*/}
                  {/*  </a>*/}
                  {/*  <div onClick={onAndroidDownloadBtnClick} className={styles.android_download}></div>*/}
                  {/* </div>*/}
                </div>

                {
                  // creators && creators.length > 0 ? <>
                  //   <div className={styles.topic_title}>{t("recommendedCreators")}</div>
                  //   <div className={styles.recommend_creator_box}>
                  //     <div ref={hotUserListRef} className={styles.recommend_creator_wrap}>
                  //       {
                  //         creators.map( item => {
                  //           return <div style={{background: item.background ? `url(${item.background}) center top / cover no-repeat` : '#132025'}} className={styles.creator_item_box}>
                  //             <div className={styles.creator_item} onClick={()=>gotoUserCenter(item.id)}>
                  //               <img className={styles.item_avatar} src={item.headPic}></img>
                  //               <div className={styles.item_userinfo}>
                  //                 <div className={styles.item_username}>{item.nickName}</div>
                  //                 <div className={styles.item_bio}>{item.introduce}</div>
                  //                 <div className={styles.item_post}>
                  //                   <span>{numFormatNotRound(item.postCount)} {t('imomoPersonPost')} </span>
                  //                   <span>{numFormatNotRound(item.followers)} {t('imomoPersonFollowers1')}</span>
                  //                 </div>
                  //               </div>
                  //             </div>
                  //           </div>;
                  //         })
                  //       }
                  //     </div>
                  //     {
                  //       creators && creators.length > 5 && (
                  //         <>
                  //           {
                  //             creatorInteraction.left && (
                  //               <div onClick={onHotUserLeftClick} className={styles.left_icon}></div>
                  //             )
                  //           }
                  //           {
                  //             creatorInteraction.right && (
                  //               <div onClick={onHotUserRightClick} className={styles.right_icon}></div>
                  //             )
                  //           }
                  //         </>
                  //       )
                  //     }
                  //   </div>
                  // </> : null
                }

                {
                  // hotPosts && hotPosts.length > 0 && (
                  //   <>
                  //     <div className={styles.topic_title}>{t('recommended')}</div>
                  //     <div className={styles.recommend_post_wrap}>
                  //       <ul ref={hotPostListRef} className={`${hotPosts.length > 5 ? styles.more_five : styles.then_five} ${hotPosts.length > 10 && styles.more_ten}`}>
                  //         {
                  //           hotPosts.map(item => (
                  //             <li key={item.id}>
                  //               <ImgCard hideEmoji={true} onSendTipClick={onRewardHotPostClick} onShowSettingInfo={onShowSettingInfo} onShowDetails={() => toDetails(item)} showShare={ (e) => { showShareModal(e, item) } } item={item}/>
                  //
                  //             </li>
                  //           ))
                  //         }
                  //       </ul>
                  //       {
                  //         hotPosts.length > 10 && (
                  //           <>
                  //             {
                  //               hotPostInteraction.left && (
                  //                 <div onClick={onHotPostLeftClick} className={styles.left_icon}></div>
                  //               )
                  //             }
                  //             {
                  //               hotPostInteraction.right && (
                  //                 <div onClick={onHotPostRightClick} className={styles.right_icon}></div>
                  //               )
                  //             }
                  //
                  //           </>
                  //         )
                  //       }
                  //     </div>
                  //   </>
                  // )
                }

                {/* <div className={styles.topic_title}>{t("new")}</div> */}
                <div className={styles.tag_list_wrapper}>
                  <div ref={modelTagListRef} className={styles.tag_list}>
                    <div onClick={() => onModelTagChange('all')} className={`${styles.tag_item_all} ${modelTagIndex === "all" && styles.tag_item_active}`}>
                      All
                    </div>
                    {
                      modelTagList.map(item => {
                        return (
                          <div onClick={() => onModelTagChange(item.trainingId)} key={item.id} className={`${styles.tag_item} ${modelTagIndex === item.trainingId && styles.tag_item_active}`}>
                            <img src={item.testImage} />
                            <div className={styles.model_name_box}>
                              {router.locale === 'ja' ? item.modelNameJp ?? item.modelName : item.modelName}
                            </div>
                          </div>
                        )
                      })
                    }
                  </div>
                  <div onClick={onModleLeftClick} className={styles.left_icon}></div>
                  <div onClick={onModleRightClick} className={styles.right_icon}></div>
                </div>
                <div className={`${styles.select_content}`}>
                  <div style={{width:"100px"}}>
                    <Select
                      defaultValue={sortBySelectIndex}
                      value={sortBySelectIndex}
                      style={{
                        minWidth: 90,
                        fontSize:"18px"
                      }}
                      onChange={(v)=>setSortBySelectIndex(v)}
                      options={[
                        {
                          value: 0,
                          label: 'Newest',
                        },
                        // {
                        //   value: 1,
                        //   label: 'Most popular',
                        // },
                        // {
                        //   value: 2,
                        //   label: 'My focus',
                        // },
                      ]}
                    />
                  </div>
                  <div className={styles.right_wrapper}>
                    <div onClick={() => router.push('/createPost/create')} className={styles.create_post_btn}>
                      <div className={styles.icon}></div>
                      <span>{t('discoverCreatePostBtnText')}</span>
                    </div>
                    <div onClick={() => router.push('/character')} className={styles.generate_btn}>
                      <div className={styles.chat_icon}></div>
                      <span>{t('Virtual Companion')}</span>
                    </div>
                    <div onClick={() => router.push('/generate')} className={`${styles.generate_btn} ${styles.margin_right_0}`}>
                      <div className={styles.icon}></div>
                      <span>{t('imomoGenerate')}</span>
                    </div>
                  </div>

                  {/* <DiscoverSearch lora={modelTagIndex} onChange={searchChange}/>*/}
                </div>

                <div id={'scrollDiv'} className={styles.screenShots_content} style={sourceList.length <= 0 ? {height: '1000px'} : {}}>
                  {
                    pageType.current === 2 ?
                      <Masonry
                        id={`zone-masonry`}
                        breakpointCols={breakpointColumnsObj}
                        className='my-masonry-grid'
                        columnClassName='my-masonry-grid_column'
                        columnAttrs={{ className: 'should be overridden', 'data-test': '', style: { '--test': 'test' }}}
                      >
                        {
                          sourceList && sourceList.map((item, index) => {
                            if (item.screenshotRsp) {
                              return item.screenshotRsp.showContent && (
                                <div key={index}>
                                  <ImgCard onSendTipClick={onRewardHotPostClick} onShowSettingInfo={onShowSettingInfo} onShowDetails={() => toDetails(item.screenshotRsp)} showShare={ (e) => { showShareModal(e, item) } } item={item.screenshotRsp}/>
                                </div>
                              );
                            } else if (item.campaignBannerRsp) {
                              return (
                                <BannerCard toGameDetails={ () => { toGameDetails(item) } } key={index}
                                            item={item.campaignBannerRsp}/>
                              );
                            } else if (item.activityBannerRsp) {
                              return (
                                <ActivityCard isNew={false} isLogin={state.isLogin} changePercent={changePercent} reGetData={reGetData} upLoad={upLoad}
                                              key={index} item={item.activityBannerRsp}/>
                              );
                            }
                          })
                        }
                      </Masonry>
                      :
                      pageType.current === 1 ?
                        <Masonry
                          id={`nft-sell-masonry`}
                          breakpointCols={breakpointColumnsObj}
                          className='my-masonry-grid'
                          columnClassName='my-masonry-grid_column'
                          columnAttrs={{ className: 'should be overridden', 'data-test': '', style: { '--test': 'test' }}}
                        >
                          {
                            sourceList && sourceList.length && sourceList.map((item, index) => {
                              item.likes = item.likeNum
                              return (
                                <div key={`${item.id}${index}`} onClick={() => gotoDetails(item)}>
                                  {/* <div style={{ height:'200px', backgroundColor:'red', color:'#fff' }}>test</div> */}
                                  <ImgCardNft toDetails={ () => { toNftDetails(item) } }  showShare={ (e) => { showShareModal(e, item) } } item={item}/>
                                </div>
                              )
                            })
                          }
                        </Masonry>
                        : null
                  }
                </div>
              </div>

            </div>
        }
        {
          showSelectWrap ? <MobileBottomSortSelect nftsell={true} id={"box"} visible={showSelectWrap} index={sortBy} onChange={mobileSortClick} closeBar={()=>setShowSelectWrap(false)} /> : null
        }
      </Layout>
      <Modal destroyOnClose={true} wrapClassName={'gamie-zone-modal-wrap'} width={365} closable={true} maskClosable={false} footer={null} closeIcon={<></>} centered visible={rewardVis} className={'gamie-zone-modal'}>
        <div className={styles.reward_modal_page}>
          <div className={styles.header_wrapper}>
            <div className={styles.title}>{t('sendATipTitle')}</div>
          </div>
          <div className={styles.user_info_wrapper}>
            <div className={styles.head_pic}>
              <Avatar className={styles.header_img_Avatar} src={rewardUserInfo.headPic}/>
            </div>
            <div className={styles.user_name}>
              {rewardUserInfo.nickName}
            </div>
          </div>
          <Form colon={false} form={rewardFrom} onFinish={onRewardFinish} className={styles.form_sty} layout={"vertical"}>
            <Form.Item rules={[
              {required: true, message: 'Please enter the amount'},
              {
                validator: (_, value) => {
                  return value > igmBalance ? Promise.reject(t('sendTipRubyAmountInsufficientErrorText')) : Promise.resolve();
                }
              }
            ]} name={'ruby'}>
              <InputNumber placeholder={t('sendTipRubyAmount')} min={1} className={`${styles.input_wra} ${styles.input_wra_placeholder}`} />
            </Form.Item>

            <Form.Item name={'comment'}>
              <Input.TextArea autoSize={true} maxLength={200} showCount placeholder={t('sendTipMessagePlaceholder')} className={`${styles.text_area} ${styles.input_wra_placeholder}`} />
            </Form.Item>

            <div className={styles.btn_wrapper}>
              <div onClick={() => setRewardVis(false)} className={styles.cancel_btn}>
                {t('cancel')}
              </div>
              <Button htmlType={'submit'} loading={rewardLoading} className={styles.send_btn}>{t('sendTipSendBtnText')}</Button>
            </div>
          </Form>
        </div>
      </Modal>
      <ActivityCard isNew={true}
                    isLogin={isLogin} changePercent={changePercent}
                    reGetData={reGetData} upLoad={upLoad}>
      </ActivityCard>
    </>
  );







};


export default GamieZone;
