import {t} from "i18next";
import {getYearMonthDayTimeStampNew} from "@/tools/action";


export const getDateDiffStatistics = (dateTimeStamp) => {
  // dateTimeStamp是一个时间毫秒，注意时间戳是秒的形式，在这个毫秒的基础上除以1000，就是十位数的时间戳。13位数的都是时间毫秒。
  var result = "Just Now";
  var minute = 1000 * 60;      // 把分，时，天，周，半个月，一个月用毫秒表示
  var hour = minute * 60;

  var day = hour * 24;

  var week = day * 7;

  var halfamonth = day * 15;

  var month = day * 30;


  var now = new Date().getTime();   // 获取当前时间毫秒

  var diffValue = now - dateTimeStamp;// 时间差


  if (diffValue < 0) {
    return;
  }

  var minC = diffValue / minute;  // 计算时间差的分，时，天，周，月

  var hourC = diffValue / hour;

  var dayC = diffValue / day;

  var weekC = diffValue / week;

  var monthC = diffValue / month;


  if (monthC >= 1) {
    var date =  new Date(dateTimeStamp);

    var months = ["Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec"];

    result = date.toLocaleDateString().replace(/\//g, "-") + " " + date.toTimeString().substr(0, 8);
    var year = result.split(" ")[0].split("-")[0];
    var mon = result.split(" ")[0].split("-")[1];
    var days = result.split(" ")[0].split("-")[2];

    var nowYear = new Date().getFullYear();

    // result = `${days} ${months[parseInt(mon) - 1]} ${parseInt(year) === nowYear ? '' : year} `;
    result = getYearMonthDayTimeStampNew(date);

    // result = " " + parseInt(monthC) + t("monthAgo");

  } else if (weekC >= 1) {

    result = " " + parseInt(weekC) + " " + (parseInt(weekC) > 1 ? t("statisticsWeeksAgo") :  t("statisticsWeekAgo"));

  } else if (dayC >= 1) {

    result = " " + parseInt(dayC) + " " + (parseInt(dayC) > 1 ? t("statisticsDaysAgo") : t("statisticsDayAgo"));

  } else if (hourC >= 1) {

    result = " " + parseInt(hourC) + " " + (parseInt(hourC) > 1 ? t("statisticsHoursAgo") : t("statisticsHourAgo"));

  } else if (minC >= 1) {

    result = " " + parseInt(minC) + " " + (parseInt(minC) > 1 ? t('statisticsMinsAgo') : t("statisticsMinAgo"));

  } else {
    result = t("justPub");
  }

  return result;
}

export const getDateDiff = (dateTimeStamp) => {
  // dateTimeStamp是一个时间毫秒，注意时间戳是秒的形式，在这个毫秒的基础上除以1000，就是十位数的时间戳。13位数的都是时间毫秒。
  var result = "Just Now";
  var minute = 1000 * 60;      // 把分，时，天，周，半个月，一个月用毫秒表示
  var hour = minute * 60;

  var day = hour * 24;

  var week = day * 7;

  var halfamonth = day * 15;

  var month = day * 30;


  var now = new Date().getTime();   // 获取当前时间毫秒

  var diffValue = now - dateTimeStamp;// 时间差


  if (diffValue < 0) {
    return;
  }

  var minC = diffValue / minute;  // 计算时间差的分，时，天，周，月

  var hourC = diffValue / hour;

  var dayC = diffValue / day;

  var weekC = diffValue / week;

  var monthC = diffValue / month;


  if (monthC >= 1) {
    var date =  new Date(dateTimeStamp);

    var months = ["Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec"];

    result = date.toLocaleDateString().replace(/\//g, "-") + " " + date.toTimeString().substr(0, 8);
    var year = result.split(" ")[0].split("-")[0];
    var mon = result.split(" ")[0].split("-")[1];
    var days = result.split(" ")[0].split("-")[2];

    var nowYear = new Date().getFullYear();

    // result = `${days} ${months[parseInt(mon) - 1]} ${parseInt(year) === nowYear ? '' : year} `;
    result = getYearMonthDayTimeStampNew(date);

    // result = " " + parseInt(monthC) + t("monthAgo");

  } else if (weekC >= 1) {

    result = " " + parseInt(weekC) + t("weekAgo");

  } else if (dayC >= 1) {

    result = " " + parseInt(dayC) + t("dayAgo");

  } else if (hourC >= 1) {

    result = " " + parseInt(hourC) + t("hourAgo");

  } else if (minC >= 1) {

    result = " " + parseInt(minC) + t("minAgo");

  } else {
    result = t("justPub");
  }

  return result;

};


export const getDateDiffComment = (dateTimeStamp) => {
  // dateTimeStamp是一个时间毫秒，注意时间戳是秒的形式，在这个毫秒的基础上除以1000，就是十位数的时间戳。13位数的都是时间毫秒。
  var result = "Just Now";
  var minute = 1000 * 60;      // 把分，时，天，周，半个月，一个月用毫秒表示
  var hour = minute * 60;

  var day = hour * 24;

  var week = day * 7;

  var halfamonth = day * 15;

  var month = day * 30;


  var now = new Date().getTime();   // 获取当前时间毫秒

  var diffValue = now - dateTimeStamp; // 时间差


  if (diffValue < 0) {
    return;
  }

  var minC = diffValue / minute;  // 计算时间差的分，时，天，周，月

  var hourC = diffValue / hour;

  var dayC = diffValue / day;

  var weekC = diffValue / week;

  var monthC = diffValue / month;


  if (monthC >= 1) {

    var date =  new Date(dateTimeStamp);

    var months = ["Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec"];

    result = date.toLocaleDateString().replace(/\//g, "-") + " " + date.toTimeString().substr(0, 8);
    var year = result.split(" ")[0].split("-")[0];
    var mon = result.split(" ")[0].split("-")[1];
    var days = result.split(" ")[0].split("-")[2];

    var nowYear = new Date().getFullYear();

    // result = `${days} ${months[parseInt(mon) - 1]} ${parseInt(year) === nowYear ? '' : year} `;
    result = getYearMonthDayTimeStampNew(date);

    // result = " " + parseInt(monthC) + "M";

  } else if (weekC >= 1) {

    result = " " + parseInt(weekC) + t("weeksAgo");

  } else if (dayC >= 1) {

    result = " " + parseInt(dayC) + t("daysAgo");

  } else if (hourC >= 1) {

    result = " " + parseInt(hourC) + t("hoursAgo");

  } else if (minC >= 1) {

    result = " " + parseInt(minC) + t("minutesAgo");

  } else {
    result = t("justPub");
  }

  return result;

};


// 格式为 Mar 23
export const getMonthWithDay = (timeStamp) => {
  let month = new Date(timeStamp).getMonth() + 1;
  switch (month) {
    case 1:
      month = 'Jan';
      break;
    case 2:
      month = 'Feb';
      break;
    case 3:
      month = 'Mar';
      break;
    case 4:
      month = 'Apr';
      break;
    case 5:
      month = 'May';
      break;
    case 6:
      month = 'Jun';
      break;
    case 7:
      month = 'Jul';
      break;
    case 8:
      month = 'Aug';
      break;
    case 9:
      month = 'Sep';
      break;
    case 10:
      month = 'Oct';
      break;
    case 11:
      month = 'Nov';
      break;
    case 12:
      month = 'Dec';
      break;
    default:
      break;
  }
  return month + " " + (new Date(timeStamp).getDate() < 10 ? '0' + new Date(timeStamp).getDate() : new Date(timeStamp).getDate()) ;
};
