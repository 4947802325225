import React, {useEffect, useRef, useState} from "react";
import {
  Affix,
  Avatar,
  Button, Carousel,
  Form,
  Image,
  Input,
  InputNumber,
  message,
  Modal,
  Popover,
  Skeleton,
  Spin,
  Typography
} from "antd";
import styles from "./index.module.scss";
import {CloseOutlined, EyeOutlined, LoadingOutlined} from "@ant-design/icons";
import {FacebookShareButton, TelegramIcon, TelegramShareButton, WhatsappShareButton, TwitterShareButton, InstapaperShareButton, LineShareButton} from "react-share";
import {coinsNoticeActionNew, emojiToText, isMobile, textToEmoji} from "@/tools/action";
import {
  APIBuyNFTGoods, APIDeleteNFTComment, APIDeleteNFTGoods, APIDiscoverRewards, APIGetIGMBalance,
  APIGetNFTGoodsDetails,
  APIGetProfile, APINFTAddComment, APINFTGiveLike, APINFTUnLike, APIPersonCenterUserDetail, APIScreenShotsAddComment,
  APIScreenShotsDeleteById,
  APIScreenShotsGetComment,
  APIScreenShotsGetDetails,
  APIScreenShotsGiveLike,
  APIScreenShotsShare, APIUserFollow
} from "@/api";
import useDispatchAction from "@/hooks/useDisptachAction";
import {setIGMBalance, setLoginModalHidden, setLoginShowModal} from "@/redux/actions/home";
import {numFormat, numFormatNotRound} from "@/tools/numFormat";
import {ReportReason} from "@/components/ReportReason";
import {useRouter} from "next/router";
import {getDateDiffComment} from "@/tools/DateFormat";
import {useSelector} from "react-redux";
import ReactPlayer from "react-player/lazy";
import {useTranslation} from "react-i18next";

const headBase = '';
const gameBase = '';

const bannerBase = process.env.NODE_ENV === 'production' ?
  'https://igamie.oss-ap-southeast-1.aliyuncs.com/real' :
  'https://igamie.oss-ap-southeast-1.aliyuncs.com/dev';

const defaultHead = "https://igamie-web.s3.ap-southeast-1.amazonaws.com/real/2021-08-19/c62bfa34-9941-41f3-81d0-3733038a09d5.png";

const {Paragraph, Text} = Typography;


const emojiMap = {
  1: styles.emoji1,
  2: styles.emoji2,
  3: styles.emoji3,
  4: styles.emoji4,
  5: styles.emoji5,
  6: styles.emoji6,
  7: styles.emoji7,
  8: styles.emoji8,
  9: styles.emoji9,
  10: styles.emoji10,
};

function cdn(path) {
  if (path && path.toString().indexOf("http") === -1) {
    path = bannerBase + path;
  }
  // return path && path.replace(/igamie-web.s3.ap-southeast-1.amazonaws.com/, 'cdn.igamie.com');
  return path;
}

const antIcon = (
  <LoadingOutlined
    style={{
      fontSize: 55,
      color: '#F488B9'
    }}
    spin
  />
);

const antSendIcon = (
  <LoadingOutlined
    style={{
      fontSize: 22,
      color: '#ff9345'
    }}
    spin
  />
);

const antCommentIcon = (
  <LoadingOutlined
    style={{
      fontSize: 34,
      color: '#ff9345'
    }}
    spin
  />
);

// 用户详情弹出框
const UserProfile = (props) => {
  const {userId, stateChange, setStateChange, nickName} = props;
  const [userInfo, setUserInfo] = useState({});
  let [isFollowed, setIsFollowed] = useState(2);
  const updateFollowState = (id, state) => {
    APIUserFollow({
      userId: id
    }).then(resp => {
      if (resp.result) {
        let newState = state === 0 ? 1 : 0;
        setIsFollowed(newState);
        message.success(resp.resultMessage);
        // 相同用户的评论，同时修改状态
        setStateChange && setStateChange();
      } else {
        message.error(resp.resultMessage);
      }
    });
  };
  useEffect(() => {
    APIGetProfile({
      anotherUserId: userId
    }).then(resp => {
      if (resp.success) {
        setUserInfo(resp.result);
        setIsFollowed(resp.result.isFollowed);
      }
    });
  }, [userId, isFollowed, stateChange]);
  const followBtnStyle = {
    color: '#f90',
    border: '2px solid #f90',
    borderRadius: '5px'
  };
  const unFollowStyle = {
    color: '#09f',
    border: '2px solid #09f',
    borderRadius: '5px'
  };
  return (
    <div className={styles.userProfile}>
      <div className={styles.avatarInfo}>
        <Avatar size={48} src={userInfo.headPic ? userInfo.headPic : ''}></Avatar>
        <div className={styles.nameInfo}>
          <span>{userInfo.nickName ? userInfo.nickName : nickName}</span>
          {
            isFollowed !== 2 &&
            <div>
              <Button onClick={(e) => {
                e.stopPropagation();
                updateFollowState(userId, isFollowed);
              }}
                      style={followBtnStyle}
                      ghost
                      type='primary'>
                {(isFollowed === 1) ? 'UnFollow' : 'Follow'}</Button>
            </div>
          }
        </div>
      </div>
      <div className={styles.otherInfo}>
        <div className={styles.item}>
          <span className={styles.count}>{userInfo.postCount ? userInfo.postCount : 0}</span>
          <span className={styles.text}>POSTS</span>
        </div>
        <div className={styles.item}>
          <span className={styles.count}>{userInfo.followers ? userInfo.followers : 0}</span>
          <span className={styles.text}>FOLLOWERS</span>
        </div>
        <div className={styles.item}>
          <span className={styles.count}>{userInfo.followering ? userInfo.followering : 0}</span>
          <span className={styles.text}>FOLLOWING</span>
        </div>
      </div>
    </div>
  );
};

// 分享组件
const ShareModal = (props) => {
  let { t, i18n} = useTranslation();

  const [shareLink, setShareLink] = useState("");
  useEffect(() => {
    setShareLink(window.location.href);
  }, []);

  const copyLink = () => {
    console.log(window.location.origin);
    var Url2 = shareLink;
    if (props.isNFTShop) {
      Url2 = window.location.origin + '/discover?businessId=' + props.shotId + '&zone=nft';
    } else {
      Url2 = window.location.origin + '/discover?businessId=' + props.shotId + '&zone=zone';
    }
    var oInput = document.createElement('input');
    oInput.value = Url2;
    document.body.appendChild(oInput);
    oInput.select(); // 选择对象
    document.execCommand('Copy');// 执行浏览器复制命令
    oInput.className = 'oInput';
    oInput.style.display = 'none';
    document.body.removeChild(oInput);
    message.success('copy succeeded');
    props.unShow();
  };

  const stopPro = (e) => {
    e.stopPropagation();
    props.unShow();
  };
  return props.show ? (
    <div className={'modal'} onClick={(e) => stopPro(e)}>
      <div onClick={(e) => e.stopPropagation()} className={'modal-wrapper'}>
        <div className={'title-bar'}>
          {
            props.item.isVideo === 0 ?
              <img className={"share-img"} src={cdn(props.item.images.split('|')[0])}/> :
              <img className={"share-img"} src={cdn(props.item.images.split("|")[1])}/>
          }
          <div className={'back'}>
            {/* <span onClick={() => props.unShow()} style={{fontSize: '26px', fontWeight: 'bold'}}>*/}
            {/*  <CloseOutlined/>*/}
            {/* </span>*/}
          </div>
        </div>
        <div className={'share-content-text'}>
          {t('discoverShareHitText')}
        </div>
        <div onClick={() => props.addShare()} className={'share-third'}>
          <div className={'share-item'}>
            <TwitterShareButton
              url={shareLink}
              children={<div className={'twitter-book'}/>}
            />
          </div>
          <div className={'share-item'}>
            <InstapaperShareButton
              url={shareLink}
              children={<div className={'ins-book'}/>}
            />
          </div>
          <div className={'share-item'}>
            <LineShareButton
              url={shareLink}
              children={<div className={'line-book'}/>}
            />
          </div>
          <div className={'share-item'}>
            <FacebookShareButton
              url={shareLink}
              children={<div className={'face-book'}/>}
            />
          </div>
          <div className={'share-item'}>
            <WhatsappShareButton
              url={shareLink}
              children={<div className={'message-book'}/>}
            />
          </div>
          <div className={'share-item'}>
            <TelegramShareButton
              url={shareLink}
              children={<div className={'telegram-book'}/>}
            />
          </div>


        </div>
        <div className={'share-bottom-text'}>

        </div>
        <div style={{height: "30px"}}></div>
        <div onClick={() => copyLink()} className={'share-copy-button'}>
          {t('discoverShareBtnText')} <span className={"copy-icon"}></span>
        </div>
      </div>
    </div>
  ) : null;
};

// 父级评论
const CommentsWidget = ({
                          item,
                          parentThumbClick,
                          childThumbClick,
                          followStateChange,
                          changeState,
                          onViewMoreClick,
                          replyComment,
                          isNFTShop,
                          onDelete,
                          user,
                          onReport
                        }) => {
  const router = useRouter();

  const [mobile, setMobile] = useState(false);

  const toUserDetails = (userId) => {
    // e.stopPropagation();
    if (isNFTShop) {
      return;
    }
    router.push('/personal-center/[userId]', `/personal-center/${userId}`, {locale: router.locale});
  };
  const onChildThumbClick = (commentId, userId, isLike) => {
    childThumbClick?.(item.id, commentId, item.userId, userId, isLike);
  };

  useEffect(() => {
    setMobile(isMobile());
  }, [])

  return (
    <div key={item.id} className={styles.comments_parents}>
      <div className={styles.c_header_wrapper}>
        <div className={styles.c_header_user}>
          <div className={styles.c_header_img}>
            {/* <Popover overlayClassName={'gamie-zone-popover'} color='#fff' placement='bottomLeft' content={() => <UserProfile*/}
            {/*  userId={item.userId} stateChange={followStateChange} setStateChange={() => {*/}
            {/*  changeState?.();*/}
            {/* }}/>}>*/}
            {/*  <Avatar className={styles.c_header_img_Avatar} onClick={() => toUserDetails(item.userId)} src={item.headPic} />*/}
            {/* </Popover>*/}
            <Avatar className={styles.c_header_img_Avatar} onClick={() => toUserDetails(item.userId)}
                    src={item.headPic}/>
          </div>
          <div onClick={() => toUserDetails(item.userId)} className={styles.c_user_name}>{item.nickName}</div>
          <div
            className={styles.c_time_ago}>{getDateDiffComment(isNFTShop ? item.gmtCreateStamp : item.createTimeNum)}</div>
          {/* {*/}
          {/*  isNFTShop && (*/}
          {/*    <>*/}
          {/*      {*/}
          {/*        item.userId === user.id && (*/}
          {/*          <div onClick={() => onDelete?.(item.id)} className={styles.c_delete_icon}></div>*/}
          {/*        )*/}
          {/*      }*/}
          {/*    </>*/}
          {/*  )*/}
          {/* }*/}
        </div>
        <div className={styles.c_action_wra}>
          <div className={styles.c_thumbs_wra}>
            {
              isNFTShop ? (
                <>
                  <div onClick={() => parentThumbClick?.(item.id, item.userId, item.isLikeDiscuss)}
                       className={item.isLikeDiscuss ? styles.c_thumbs_icon_active : styles.c_thumbs_icon}></div>
                  <div className={styles.c_thumbs_num}>{numFormat(item.likeNums)}</div>
                </>
              ) : (
                <>
                  <div onClick={() => parentThumbClick?.(item.id, item.userId)}
                       className={item.isLike === 1 ? styles.c_thumbs_icon_active : styles.c_thumbs_icon}></div>
                  <div className={styles.c_thumbs_num}>{numFormat(item.likes)}</div>
                </>

              )
            }
          </div>

          <div className={styles.c_rec_wra}>

            {
              isNFTShop ? (
                <Popover placement={mobile ? "bottomRight" : 'bottom'} overlayClassName={'gamie-zone-comment-popover'} overlayInnerStyle={{
                  border: '1px solid #2B3032',
                  borderRadius: 10,
                  // width: 129,
                  display: 'flex',
                  justifyContent: 'center',
                  overflow: 'hidden',
                  padding: 0
                }} color={"#132024"}
                         content={() => <CommentReplyMenu
                           user={user}
                           commentItem={item}
                           onReply={() => replyComment?.(item.nickName, item.userId, item.id, item.id, 2)}
                           onDelete={() => onDelete?.(item.id)}
                           onReport={() => onReport?.(item)}
                         />} >
                  <div className={styles.c_rec_icon}></div>
                </Popover>
              ) : (
                <div onClick={() => replyComment?.(item.nickName, item.userId, item.id, item.id, 2)} className={styles.c_rec_icon}></div>
              )
            }
            <div className={styles.c_rec_num}>{numFormat(item.subComments?.length)}</div>
          </div>
        </div>
      </div>
      <div className={styles.c_content_wrapper}>
        <CommentDetail comment={item.comment}/>
      </div>
      <div className={styles.c_child}>
        {
          item.isExpand ?
            item.subComments && item.subComments.map((itemChild) => <CommentsChildWidget item={itemChild}
                                                                                                childThumbClick={onChildThumbClick}
                                                                                                followStateChange
                                                                                                changeState
                                                                                                replyComment={replyComment}
                                                                                                parentsId={item.id}
                                                                                                onDelete={onDelete}
                                                                                                isNFTShop user={user} onReport={onReport} />) :
            item.subComments && item.subComments.map((itemChild, index) => index === 0 &&
              <CommentsChildWidget item={itemChild} childThumbClick={onChildThumbClick} followStateChange changeState
                                   replyComment={replyComment} parentsId={item.id} isNFTShop={isNFTShop} onDelete={onDelete} user={user} onReport={onReport} />)
        }
      </div>

        {
          (item.subComments && item.subComments.length > 1 && !item.isExpand) && (
            <div className={styles.c_view_box}>
              <div onClick={() => onViewMoreClick?.(item.id)} className={styles.c_view_more}>
                <div className={styles.down_icon}></div>
                <div className={styles.hit_txt}>View More</div>
              </div>
            </div>
          )
        }

    </div>
  );
};

// 子级评论
const CommentsChildWidget = ({
                               item,
                               childThumbClick,
                               changeState,
                               followStateChange,
                               replyComment,
                               parentsId,
                               isNFTShop,
                                onDelete,
                                user,
                               onReport
                             }) => {
  const router = useRouter();

  const [mobile, setMobile] = useState(false);

  const toUserDetails = (userId) => {
    // e.stopPropagation();
    router.push('/personal-center/[userId]', `/personal-center/${userId}`, {locale: router.locale});
  };

  useEffect(() => {
    setMobile(isMobile());
  }, []);

  return (
    <div key={item.id} className={styles.comments_child}>
      <div className={styles.c_header_wrapper}>
        <div className={styles.c_header_user}>
          <div className={styles.c_header_img}>
            {/* <Popover overlayClassName={'gamie-zone-popover'} color='#fff' placement='bottomLeft' content={() => <UserProfile*/}
            {/*  userId={item.userId} stateChange={followStateChange} setStateChange={() => {*/}
            {/*  changeState?.();*/}
            {/* }}/>}>*/}
            {/*  <Avatar onClick={() => toUserDetails(item.userId)} className={styles.c_header_img_Avatar} src={item.headPic} />*/}
            {/* </Popover>*/}
            <Avatar onClick={() => toUserDetails(item.userId)} className={styles.c_header_img_Avatar}
                    src={item.headPic}/>
          </div>
          <div onClick={() => toUserDetails(item.userId)} className={styles.c_user_name}>{item.nickName}</div>
          <div
            className={styles.c_time_ago}>{getDateDiffComment(isNFTShop ? item.gmtCreateStamp : item.createTimeNum)}</div>
          {/* {*/}
          {/*  isNFTShop && (*/}
          {/*    <>*/}
          {/*      {*/}
          {/*        item.userId === user.id && (*/}
          {/*          <div onClick={() => onDelete?.(item.id)} className={styles.c_c_delete_icon}></div>*/}
          {/*        )*/}
          {/*      }*/}
          {/*    </>*/}
          {/*  )*/}
          {/* }*/}
        </div>
        <div className={styles.c_action_wra}>
          <div className={styles.c_thumbs_wra}>
            {
              isNFTShop ? (
                <>
                  <div onClick={() => childThumbClick?.(item.id, item.userId, item.isLikeDiscuss)}
                       className={item.isLikeDiscuss ? styles.c_thumbs_icon_active : styles.c_thumbs_icon}></div>
                  <div className={styles.c_thumbs_num}>{numFormat(item.likeNums)}</div>
                </>
              ) : (
                <>
                  <div onClick={() => childThumbClick?.(item.id, item.userId)}
                       className={item.isLike === 1 ? styles.c_thumbs_icon_active : styles.c_thumbs_icon}></div>
                  <div className={styles.c_thumbs_num}>{numFormat(item.likes)}</div>
                </>
              )
            }

          </div>
          <div className={styles.c_rec_wra}>
            {
              isNFTShop ? (
                <Popover placement={mobile ? "bottomRight" : 'bottom'} overlayClassName={'gamie-zone-comment-popover'} overlayInnerStyle={{
                  border: '1px solid #2B3032',
                  borderRadius: 10,
                  // width: 129,
                  display: 'flex',
                  justifyContent: 'center',
                  overflow: 'hidden',
                  padding: 0
                }} color={"#132024"}
                         content={() => <CommentReplyMenu
                           user={user}
                           commentItem={item}
                           onReply={() => replyComment?.(item.nickName, item.userId, parentsId, item.id, 2)}
                           onDelete={() => onDelete?.(item.id)}
                           onReport={() => onReport?.(item)}
                         />} >
                  <div className={styles.c_rec_icon}></div>
                </Popover>
              ) : (
                <div onClick={() => replyComment?.(item.nickName, item.userId, parentsId, item.id, 2)}
                     className={styles.c_rec_icon}></div>
              )
            }

            <div className={styles.c_rec_num}>{numFormat(item.subComments?.length)}</div>
          </div>
        </div>
      </div>
      <div className={styles.c_content_wrapper}>
        <CommentDetail comment={item.comment}/>
      </div>
    </div>
  )
};

// @评论转换
const CommentDetail = (props) => {
  // @用户名 高亮显示
  const {comment} = props;
  const reg = /(@[^@^\s]+) /g;
  const resultArray = comment.match(reg);
  const finalResult = [];
  // 如果没有 @用户名，则match 会返回null
  if (resultArray !== null) {
    const userNameIndexArr = [];
    for (let i = 0; i < resultArray.length; i++) {
      let startIndex = comment.indexOf(resultArray[i]);
      let endIndex = startIndex + resultArray[i].length;
      userNameIndexArr.push(startIndex);
      userNameIndexArr.push(endIndex);
    }
    const strLength = comment.length;
    if (userNameIndexArr[0] > 0) {
      userNameIndexArr.unshift(0);
    }
    if (userNameIndexArr[userNameIndexArr.length - 1] < strLength) {
      userNameIndexArr.push(strLength);
    }
    for (let i = 0; i < userNameIndexArr.length - 1; i++) {
      let text = comment.slice(userNameIndexArr[i], userNameIndexArr[i + 1]);
      let isUserName = false;
      if (text.indexOf('@') !== -1) {
        isUserName = true;
      }
      const commentFragment = {text, isUserName};
      finalResult.push(commentFragment);
    }
  }
  let emojiIndex = 0;
  let strArr = [];
  // 表示既有表情内容，又有@符号
  if (comment && comment.indexOf('!!emoji??') !== -1 && resultArray !== null) {
    strArr = comment.split(" ");
    const emojiText = strArr[1].split('');
    emojiIndex = Number(emojiText[emojiText.length - 1]);
  }
  return (
    <div>
      {
        // 表示有@用户名 但是没有表情
        resultArray !== null && emojiIndex === 0 ?
          finalResult.map((item, index) =>
            item.isUserName ?
              <span key={index} style={{color: '#4C89A8'}}> {item.text} </span>
              :
              item.text.indexOf("!!emoji??-") === -1 ? <span key={index}> {textToEmoji(item.text)} </span> :
                <div key={index} className={`${emojiMap[comment.split("-")[1]]}`}></div>
          )
          :
          // 有@用户名，有表情
          resultArray !== null && emojiIndex !== 0
            ?
            <div>
              <span style={{color: '#4C89A8'}}> {strArr[0]} </span>
              <div className={`${emojiMap[emojiIndex]}`}></div>
            </div> :
            // 没有@用户名，没有表情
            comment && comment.split("-")[0] === "!!emoji??" ?
              <div className={`${emojiMap[comment.split("-")[1]]}`}></div> :
              textToEmoji(comment)
      }
    </div>
  );
};

// 操作菜单
const ActionMenu = (props) => {
  let { t, i18n} = useTranslation();
  const item = props.checkUserPost.item;
  const user = props.checkUserPost.user;
  const isNFTShop = props.nftShop;
  return (
    <div style={{color: "#FFFFFF", fontSize: "15px", fontWeight: 500, fontFamily: 'PingFangTC-Medium, PingFangTC'}}>
      <div
        style={item.userId === user.id && (item.status === "P0" || item.status === "P1") ? {cursor: "pointer"} : {display: "none"}}
        onClick={props.deletePost}>Delete
      </div>
      {
        isNFTShop && (
          <div
            style={item.userId === user.id ? {cursor: "pointer"} : {display: "none"}}
            onClick={props.deleteNFT}>Delete
          </div>
        )
      }
      <div onClick={props.showReport} style={item.userId === user.id ? {display: "none"} : {cursor: "pointer"}}>{t('report')}
      </div>
    </div>
  );
};

const CommentReplyMenu = ({user, commentItem, onReply, onDelete, onReport}) => {
  let { t, i18n} = useTranslation();
  return (
    <div className={styles.comment_reply_menu}>
      <div onClick={() => onReply?.()} className={styles.menu_item}>Reply</div>
      {
        user.id === commentItem.userId ? (
          <div onClick={() => onDelete?.()} className={styles.menu_item}>Delete</div>
        ) : (
          <div onClick={() => onReport?.()} className={styles.menu_item}>{t('report')}</div>
        )
      }
    </div>
  )
};

const GamezoneCard = ({gameName, shotItem, vis, onClose, isNFTShop, buySuccess}) => {
  const UserInfo = useSelector(state => state.userInfo.userInfo.userInfo);
  const igmBalance = useSelector(state => state.home.setIGMBalance && state.home.setIGMBalance.igmBalance);

  let { t, i18n} = useTranslation();
  const dispatchAction = useDispatchAction({setLoginModalHidden, setLoginShowModal, setIGMBalance});
  const router = useRouter();

  const textAreaRefPC = useRef();
  const textAreaRefMobile = useRef();

  // image 走马灯 ref
  const carouseRef = useRef(null);

  // 打赏from
  const [rewardFrom] = Form.useForm();

  const [isMobileEdit, setIsMobileEdit] = useState(false);
  const [mobile, setMobile] = useState(false);
  const [reportType, setReportType] = useState(0);
  const [reportUserId, setReportUserId] = useState(null);
  const [state, setState] = useState({
    loading: false,
    // gamie 内容
    gamieId: null,
    gameName: null,
    gamieItem: {},
    item: {},

    // 用户信息
    user: {},

    // 分享
    showShare: false,
    shareNum: 0,

    // skuList
    skuRsp: [],

    // 当前screenshot
    isLike: false,
    likeNum: 0,
    commentNum: 0,
    viewNum: 0,
    ellipsis: true,

    // 评论
    replyUserName: null,
    replyCommentId: null,
    replyParentsCommentId: null,
    replyCommentUserId: null,
    replyLeve: 1,
  });

  const [confirmLoading, setConfirmLoading] = useState(false);

  // 确定NFT购买弹窗
  const [confirmVis, setConfirmVis] = useState(false);

  // 购买后跳转个人中心弹窗
  const [personalVis, setPersonalVis] = useState(false);

  // 删除NFT Zone
  const [deleteVis, setDeleteVis] = useState(false);

  // 正在评论
  const [adding, setAdding] = useState(false);

  // 图片加载
  const [imgLoad, setImgLoad] = useState(false);

  // 输入框值
  const [inputValue, setInputValue] = useState('');

  // 评论参数
  const [commentState, setCommentState] = useState({
    // 评论
    commentPageNum: 1,
    haveNext: false,
    commentList: [],

    // 关注状态
    commentFollowState: {},
  });

  // 头部是否滚动到了位置
  const [headAffixed, setHeadAffixed] = useState(false);

  // 输入框是否滚动到了位置
  const [inputAffixed, setInputAffixed] = useState(false);

  // 评论加载
  const [commentLoading, setCommentLoading] = useState(true);

  // 展示举报
  const [reportShow, setReportShow] = useState(false);

  // 获取滚动dom
  const [modalContainer, setModalContainer] = useState(null);

  // 删除评论ID
  const [deleteCommentId, setDeleteCommentId] = useState(null);

  // 删除评论弹窗
  const [deleteCommentVis, setDeleteCommentVis] = useState(false);

  // post发布者信息
  const [postUser, setPostUser] = useState({});

  // 取消关注弹窗
  const [showAlert, setShowAlert] = useState(false);

  // 打赏弹窗
  const [rewardVis, setRewardVis] = useState(false);

  // 图片下载加载
  const [downloading, setDownloading] = useState(false);

  // 打赏请求加载
  const [rewardLoading, setRewardLoading] = useState(false);

  // 图片Index
  const [imageIndex, setImageIndex] = useState(0);

  // 缩略图Index
  const abbreviatedIndexRef = useRef(0);

  // 分享
  const onAddShare = () => {
    APIScreenShotsShare({
      id: state.gamieId,
    }).then(resp => {
      setState({
        ...state,
        shareNum: state.shareNum + 1,
        showShare: false
      });
    });
  };
  const onCloseShare = () => {
    setState({
      ...state,
      showShare: false
    });
  };
  const onShowShare = () => {
    const token = localStorage.getItem("token");
    if (token === null || token === "") {
      dispatchAction.setLoginShowModal(true);
      return;
    }
    setState({
      ...state,
      showShare: true,
    });
  };

  // 删除post
  const deleteById = () => {
    if (vis) {
      APIScreenShotsDeleteById({
        id: shotItem.id,
      }).then(resp => {
        if (resp.result) {
          message.success("delete success");
          // this.props.back();
          onClose?.();
        }
      }).finally(() => {
        onClose?.();
      });
    } else {
      onClose?.();
    }
  };

  // 购买后提示并关闭退回列表
  const closeAllModal = () => {
    setPersonalVis(false)
    if (isMobile()) {
      router.back();
    } else {
      onClose?.();
    }
  };

  // 获取igm 余额
  const getIGMBalance = () => {
    APIGetIGMBalance().then(resp => {
      if (resp.result) {
        dispatchAction.setIGMBalance(resp.result && resp.result.balance);
      }
    });
  };

  // 点击like
  const onLikeStar = () => {
    const token = localStorage.getItem("token");
    if (token === null || token === "") {
      dispatchAction.setLoginShowModal(true);
      return;
    }
    const isLike = state.isLike;
    if (state.isLike === 1) {
      setState({
        ...state,
        isLike: 0,
        likeNum: state.likeNum - 1
      });
    } else {
      setState({
        ...state,
        isLike: 1,
        likeNum: state.likeNum + 1
      });
    }
    if (isNFTShop) {
      if (isLike === 1) {
        APINFTUnLike(JSON.stringify({
          businessId: shotItem.id,
          productId: shotItem.id,
        })).then(resp => {
          if (resp.resultCode === 10006 || resp.resultCode === 10004 || resp.resultCode === 10007 || resp.resultCode === 10005) {
            dispatchAction.setLoginShowModal(true);
            return;
          }
          if (resp.result) {
            coinsNoticeActionNew();
          }
        })
        return;
      }
      APINFTGiveLike(JSON.stringify({
        businessId: shotItem.id,
        productId: shotItem.id,
      })).then(resp => {
        if (resp.resultCode === 10006 || resp.resultCode === 10004 || resp.resultCode === 10007 || resp.resultCode === 10005) {
          dispatchAction.setLoginShowModal(true);
          return;
        }
        if (resp.result) {
          coinsNoticeActionNew();
        }
      });
      return;
    }
    APIScreenShotsGiveLike(JSON.stringify({
      type: 0,
      screenshotId: shotItem.id
    })).then(resp => {
      if (resp.resultCode === 10006 || resp.resultCode === 10004 || resp.resultCode === 10007 || resp.resultCode === 10005) {
        dispatchAction.setLoginShowModal(true);
        return;
      }
      if (resp.result) {
        coinsNoticeActionNew();
      }
    });
  };

  // 父评论点赞
  const onParentsThumbCommentLike = (commentId, userId, isLike) => {
    const token = localStorage.getItem("token");
    if (token === null || token === "") {
      dispatchAction.setLoginShowModal(true);
      return;
    }
    if (isNFTShop) {
      if (isLike) {
        APINFTUnLike(JSON.stringify({
          businessId: commentId,
          productId: shotItem.id,
        })).then(resp => {
          if (resp.result) {
            getNFTComment();
          }
        });
        return;
      }
      APINFTGiveLike(JSON.stringify({
        businessId: commentId,
        productId: shotItem.id,
      })).then(resp => {
        if (resp.result) {
          getNFTComment();
        }
      });
      return;
    }
    let allCommentList = commentState.commentList;
    const newCommentList = allCommentList.map((item, index) => {
      if (item.id === commentId) {
        const isLike = item.isLike;
        if (isLike === 1) {
          item.isLike = 0;
          item.likes = item.likes > 0 ? item.likes - 1 : 0;
          return item;
        }
        item.isLike = 1;
        item.likes = item.likes + 1;
        return item;

      }
      return item;
    });
    setCommentState({
      ...commentState,
      commentList: [...newCommentList],
    });
    APIScreenShotsGiveLike(JSON.stringify({
      id: commentId,
      parentId: commentId,
      screenshotId: shotItem.id,
    })).then(resp => {
    });
  };

  // 子评论点赞
  const onChildThumbCommentLike = (parentCommentId, childCommentId, parentUserId, childUserId, isLike) => {
    console.log(parentCommentId, childCommentId)
    const token = localStorage.getItem("token");
    if (token === null || token === "") {
      dispatchAction.setLoginShowModal(true);
      return;
    }
    if (isNFTShop) {
      if (isLike) {
        APINFTUnLike(JSON.stringify({
          businessId: childCommentId,
          productId: shotItem.id,
        })).then(resp => {
          if (resp.result) {
            getNFTComment();
          }
        });
        return;
      }
      APINFTGiveLike(JSON.stringify({
        businessId: childCommentId,
        productId: shotItem.id,
      })).then(resp => {
        if (resp.result) {
          getNFTComment();
        }
      });
      return;
    }
    let allCommentList = commentState.commentList;
    const newCommentList = allCommentList.map((item, index) => {
      if (item.id === parentCommentId) {
        const newSubComment = item.subComments.map((childItem, index) => {
          if (childItem.id === childCommentId) {
            const isLike = childItem.isLike;
            if (isLike === 1) {
              childItem.isLike = 0;
              childItem.likes = childItem.likes > 0 ? childItem.likes - 1 : 0;
              return childItem;
            }
            childItem.isLike = 1;
            childItem.likes = childItem.likes + 1;
            return childItem;
          }
          return childItem;
        });
        item.subComments = [...newSubComment];
        return item;
      }
      return item;
    });

    setCommentState({
      ...commentState,
      commentList: [...newCommentList],
    });

    APIScreenShotsGiveLike(JSON.stringify({
      id: childCommentId,
      parentId: childCommentId,
      screenshotId: shotItem.id,
    })).then(resp => {
    });

  };

  // 父级评论 子级view more
  const onParentCommentViewMore = (commentId) => {
    let allCommentList = commentState.commentList;
    const newCommentList = allCommentList.map((item, index) => {
      if (item.id === commentId) {
        item.isExpand = !item.isExpand;
        return item;
      }
      return item;
    });
    setCommentState({
      ...commentState,
      commentList: [...newCommentList],
    });
  };

  // 评论 view more
  const onCommentViewMore = () => {
    getComments();
  };

  // 去用户详情
  const toUserDetails = (e) => {
    e.stopPropagation();
    router.push('/personal-center/[userId]', `/personal-center/${state.gamieItem.userId}`, {locale: router.locale});
  };

  // 去我的用户详情
  const toMyUserDetails = () => {
    // e.stopPropagation();
    setPersonalVis(false)
    onClose?.();
    router.push('/personal-center/[userId]', `/personal-center/${UserInfo.id}`, {locale: router.locale});
  };

  // 标题内容
  const reg = new RegExp("#[a-zA-Z]+", "g");
  const descRegTag = (desc) => {
    if (desc) {
      desc = desc.replace(reg, "");
    } else {
      desc = "";
    }
    return desc;
  };

  // 评论icon 设置输入框方法
  const onSetCommentSend = (value, userId, parentsCommentId, commentId, leve) => {
    if (isMobile()) {
      setIsMobileEdit(true);
      textAreaRefMobile.current?.focus();
    } else {
      textAreaRefPC.current?.focus();
    }

    setInputValue(`@${value} `);
    setState({
      ...state,
      replyUserName: value,
      replyCommentId: commentId,
      replyCommentUserId: userId,
      replyParentsCommentId: parentsCommentId,
      replyLeve: leve,
    });

  };


  // 获取输入框内容
  const onInputChange = (e) => {
    e.persist();
    const value = e.target.value;
    if (value === '') {
      setState({
        ...state,
        replyCommentId: null,
        replyLeve: null,
        replyParentsCommentId: null,
        replyUserName: null,
        replyCommentUserId: null,
      })
    }
    setInputValue(value);
  };

  // 图片加载方法
  const onImgLoadChange = () => {
    setImgLoad(true);
  };

  // 跳转tag 搜索gamiezone
  const gotoQueryTag = (tag) => {
    // this.router.push(`/search/[...type]`, `/search/postQuery/${tag}`, {locale: this.router.locale} );
    // return window.location.href = `/${router.locale}/Discover/${tag.replace(/ /g, "_")}/`;
    // return this.router.push('/discover/[gamename]', `/discover/${tag}`,  {locale: this.router.locale} );
  };

  // 跳转游戏详情
  const gotoGameDetails = () => {
    if (state.item && state.item.skuRsp.sellingCoins) {
      router.push('/re/[gamename]', `/re/${state.item.alias}`, {locale: router.locale});
    } else {
      router.push('/g/[gamename]', `/g/${state.item.alias}`, {locale: router.locale});
    }
  };

  // 全部评论经过方法
  const onAddComment = (e, emojiType) => {
    // e.persist();
    console.log('chuangjian ')
    console.log(state.replyParentsCommentId)
    if (adding) {
      return;
    }
    setAdding(true);
    if (emojiType) {
      if (state.replyLeve === 2) {
        // 生成二级评论
        if (inputValue.indexOf(" ") === -1 || inputValue.indexOf("@") === -1) {
          setState({
            replyCommentId: null,
            replyLeve: null,
          });
          return onAddComment(e, emojiType);
        }

        addSecondLevelComment(emojiType);
      } else {
        // 生成一级评论
        addFirstLevelComment(emojiType);
      }
      return;
    }
    if (inputValue.trim() === '') {
      message.info(t("sendCommentIsNull"));
      return;
    }
    if (inputValue.length > 200) {
      message.info(t("commentLengthError"));
      return;
    }
    // setAdding(true);
    if (state.replyCommentId) {
      if (inputValue.indexOf(" ") === -1 || inputValue.indexOf("@") === -1) {
        setState({
          ...state,
          replyCommentId: null,
          replyLeve: null,
        });
        return onAddComment(e, emojiType);
      }
      addSecondLevelComment();
    } else {
      addFirstLevelComment();
    }
  };

  // 二级评论
  const addSecondLevelComment = (emojiType) => {
    console.log('二级评论')
    let commentContent = inputValue;
    let comment = '';
    if (!isNFTShop) {
      commentContent = inputValue.split(' ');
      commentContent.splice(0, 1);
      comment = ' ' + emojiType ? "!!emoji??-" + emojiType : emojiToText(commentContent.toString().replace(/,/g, " "));
    } else {
      comment = emojiType ? "!!emoji??-" + emojiType : emojiToText(commentContent.toString().replace(/,/g, " "));
    }
    if (isNFTShop) {
      APINFTAddComment(JSON.stringify({
        comment: comment,
        productId: shotItem.id,
        parentId: state.replyParentsCommentId,
      })).then(resp => {
        if (resp.resultCode === 10006 || resp.resultCode === 10004 || resp.resultCode === 10007 || resp.resultCode === 10005) {
          dispatchAction.setLoginShowModal(true);
          return;
        }
        setState({
          ...state,
          replyCommentId: null,
          replyLeve: null,
          replyParentsCommentId: null,
          replyUserName: null,
          replyCommentUserId: null,
          commentNum: state.commentNum + 1
        });
        setInputValue('');
        getNFTComment();
      }).finally(() => {
        setAdding(false);
      });
      return;
    }
    APIScreenShotsAddComment(JSON.stringify({
      comment: ` ${comment}`,
      screenshotId: shotItem.id,
      parentId: state.replyCommentId,
      inviteUserId: state.replyCommentUserId,
    })).then(resp => {
      if (resp.resultCode === 10006 || resp.resultCode === 10004 || resp.resultCode === 10007 || resp.resultCode === 10005) {
        dispatchAction.setLoginShowModal(true);
        return;
      }
      if (resp.success) {
        const newCommentItem = {
          headPic: UserInfo.headPic,
          nickName: UserInfo.nickName,
          comment: `@${state.replyUserName} ${comment}`,
          userId: UserInfo.id,
          state: true,
          likes: 0,
          isLike: 0,
          display: true,
          id: resp.result,
          createTime: new Date(),
        };
        const allCommentList = commentState.commentList;
        const newCommentList = allCommentList.map((item, index) => {
          if (state.replyParentsCommentId === item.id) {
            const newSubComment = [newCommentItem].concat(item.subComments);
            item.subComments = newSubComment;
            return item;
          }
          return item;
        });
        setInputValue('');
        setState({
          ...state,
          replyCommentId: null,
          replyLeve: null,
          replyParentsCommentId: null,
          replyUserName: null,
          replyCommentUserId: null,
          commentNum: state.commentNum + 1
        });
        setCommentState({
          ...commentState,
          commentList: [...newCommentList],
        });
      } else {
        message.error(t("addCommentError"));
      }
    }).finally(() => {
      setAdding(false);
    });
  };

  // 一级评论
  const addFirstLevelComment = (emojiType) => {
    const comment = emojiType ? "!!emoji??-" + emojiType : emojiToText(inputValue);
    if (isNFTShop) {
      APINFTAddComment(JSON.stringify({
        comment,
        productId: shotItem.id,
      })).then(resp => {
        if (resp.resultCode === 10006 || resp.resultCode === 10004 || resp.resultCode === 10007 || resp.resultCode === 10005) {
          dispatchAction.setLoginShowModal(true);
          return;
        }
        setInputValue('');
        setInputValue('');
        setState({
          ...state,
          commentNum: state.commentNum + 1,
        })
        getNFTComment();
      }).finally(() => {
        setAdding(false);
      });
      return;
    }
    APIScreenShotsAddComment(JSON.stringify({
      screenshotId: shotItem.id,
      comment,
    })).then(resp => {
      if (resp.resultCode === 10006 || resp.resultCode === 10004 || resp.resultCode === 10007 || resp.resultCode === 10005) {
        dispatchAction.setLoginShowModal(true);
        return;
      }
      if (resp.result) {
        message.success('Comment sent');
        const newComment = {
          headPic: UserInfo.headPic,
          nickName: UserInfo.nickName,
          comment: comment,
          userId: UserInfo.id,
          state: true,
          likes: 0,
          isLike: 0,
          id: resp.result,
          subComments: [],
          createTime: new Date(),
        };
        const allCommentList = commentState.commentList;
        const newCommentList = [newComment].concat(allCommentList);
        setInputValue('');
        setState({
          ...state,
          commentNum: state.commentNum + 1,
        })
        setCommentState({
          ...commentState,
          commentList: [...newCommentList],
        });
      } else {
        message.error(t("addCommentError"));
      }
    }).finally(() => {
      setAdding(false);
    });
  };

  // 删除评论
  const onDeleteComment = (commentId, isConform) => {
    console.log('删除评论')
    console.log(isConform)
    if (typeof isConform === 'undefined') {
      console.log('评论idnull')
      setDeleteCommentId(commentId);
      setDeleteCommentVis(true);
      return;
    }
    if (isConform) {
      setConfirmLoading(true);
      APIDeleteNFTComment({commentId: commentId}).then(resp => {
        if (resp.resultCode === 200) {
          setDeleteCommentId(null);
          setDeleteCommentVis(false);
          getNFTComment();
        }
      }).finally(() => {
        setConfirmLoading(false);
      });
    } else {
      setDeleteCommentId(null);
      setDeleteCommentVis(false);
    }
  };

  // 举报评论
  const onReportComment = (comment) => {
    setReportType(1);
    setReportUserId(comment.userId);
    setReportShow(true);
  };

  // 获取评论
  const getComments = () => {
    setCommentLoading(true);
    APIScreenShotsGetComment(JSON.stringify({
      screenshotId: shotItem.id,
      page_size: 5,
      page_num: commentState.commentPageNum,
      userId: UserInfo.id
    })).then(resp => {
      let commentList = commentState.commentList.concat(resp.result.records);
      const commentFollowState = {};
      const newCommentList = commentList.map(item => {
        if (!commentFollowState[item.userId]) {
          commentFollowState[item.userId] = false;
        }
        item.isExpand = false;
        return item;
      });
      setCommentState({
        commentPageNum: commentState.commentPageNum + 1,
        haveNext: resp.result.records.length < 3 || resp.result.total === 3 ? false : true,
        commentList: newCommentList,
        commentFollowState: commentFollowState,
      });
    }).finally(() => {
      setCommentLoading(false);
    });
  };

  // 获取详情
  const getGamieDetails = () => {
    setState({
      ...state,
      loading: true
    });
    console.log(UserInfo.id);
    const loginState = typeof UserInfo.id !== "undefined";
    const userInfo = JSON.parse(localStorage.getItem('userInfo')) === null ? UserInfo : JSON.parse(localStorage.getItem('userInfo'));
    APIScreenShotsGetDetails({
      screenshotId: shotItem.id
    }, loginState).then(resp => {
      resp.result.nickName = resp.result.nickName === null || resp.result.nickName === "" ? resp.result.email && resp.result.email.toString().split("@")[0] : resp.result.nickName;
      setState({
        ...state,
        user: userInfo,
        loading: false,
        gameName: gameName,
        gamieId: resp.result.id,
        gamieItem: resp.result,
        item: resp.result,
        skuRsp: resp.result.skuRsp,
        isLike: resp.result.isLike,
        likeNum: resp.result.likes,
        shareNum: resp.result.shares,
        commentNum: resp.result.comment,
        viewNum: resp.result.view
      });
      setModalContainer(document.getElementById('scCon'));
      getComments();
    });
  };

  // 手机端点击评论icon
  const mobileMessageIconClick = () => {
    textAreaRefMobile.current?.focus();
    setIsMobileEdit(true);
    // setTimeout(() => {
    //   textAreaRefMobile.current?.focus();
    // }, 1000);
  };

  // 手机端失去焦点
  const mobileInputOnBlur = () => {
    if (isMobileEdit) {
      setIsMobileEdit(false);
    }
  };

  // 确定购买
  const buyNFTGoods = () => {
    setConfirmLoading(true);
    APIBuyNFTGoods({productId: shotItem.id}).then(res => {
      if (res.result) {
      //  onClose?.(true);
        getIGMBalance();
        // if (isMobile()) {
        //   router.replace(`/nftSellOrder/${res.result}`)
        //   return;
        // }
        getNFTGoodsDetails();
        if (isMobile()) {
          setConfirmVis(false);
          setPersonalVis(true);
          return
        }
        buySuccess?.(res.result);
        setConfirmVis(false);
        setPersonalVis(true);
      }
    }).finally(() => {
      setConfirmLoading(false)
    })
  };

  // 头部固定位置返回方法
  const onHeadOnTargetChange = (affixed) => {
    if (mobile) {
      return
    }
    setHeadAffixed(affixed);
  };

  // 评论输入框固定位置返回
  const onInputOnTargetChange = (affixed) => {
    if (mobile) {
      return
    }
    setInputAffixed(affixed);
  };

  // 确定删除NFT Zone
  const deleteNFTGoods = () => {
    setCommentLoading(true);
    APIDeleteNFTGoods({productId: shotItem.id}).then(resp => {
      if (resp.resultCode === 200) {
        if (mobile) {
          router.back();
        } else {
          setDeleteVis(false);
          onClose?.();
          buySuccess?.();
        }
      }
    }).finally(() => {
      setCommentLoading(false);
    });
  };

  // 获取nft详情
  const getNFTGoodsDetails = () => {
    setState({
      ...state,
      loading: true
    });
    const loginState = typeof UserInfo.id !== "undefined";
    const userInfo = JSON.parse(localStorage.getItem('userInfo')) === null ? UserInfo : JSON.parse(localStorage.getItem('userInfo'));
    APIGetNFTGoodsDetails({
      productId: shotItem.id
    }).then(resp => {

      // 数据重置
      resp.result.nickName = resp.result.nickName === null || resp.result.nickName === "" ? resp.result.email && resp.result.email.toString().split("@")[0] : resp.result.nickName;
      resp.result.images = resp.result.productUrl;
      resp.result.desce = resp.result.description;
      resp.result.isVideo = 0;
      resp.result.bestState = 0;

      // 评论重置
      let commentNum = 0;
      const commentFollowState = {};
      const resCommentList = resp.result.discussList;
      const newCommentList = resCommentList.map(item => {
        if (!commentFollowState[item.userId]) {
          commentFollowState[item.userId] = false;
        }
        item.isExpand = false;
        item.subComments = item.secondaryDiscussList;
        commentNum += item.discussNums;
        commentNum += 1;
        return item;
      });

      setState({
        ...state,
        user: userInfo,
        loading: false,
        gameName: resp.result.gameName,
        gamieId: resp.result.id,
        gamieItem: resp.result,
        item: resp.result,
        skuRsp: resp.result.skuRsp,
        isLike: resp.result.isLike ? 1 : 0,
        likeNum: resp.result.likeNum,
        shareNum: resp.result.shares,
        viewNum: resp.result.browseNum,
        commentNum: commentNum
      });

      setCommentState({
        commentPageNum: commentState.commentPageNum + 1,
        haveNext: false,
        commentList: newCommentList,
        commentFollowState: commentFollowState,
      });
      if (!mobile) {
        setModalContainer(document.getElementById('scCon'));
      }
    }).finally(() => {
      setCommentLoading(false);
    });
  };

  // 获取NFT评论
  const getNFTComment = () => {
    APIGetNFTGoodsDetails({
      productId: shotItem.id
    }).then(resp => {
      const commentFollowState = {};
      const resCommentList = resp.result.discussList;
      const newCommentList = resCommentList.map(item => {
        if (!commentFollowState[item.userId]) {
          commentFollowState[item.userId] = false;
        }
        item.isExpand = false;
        item.subComments = item.secondaryDiscussList;
        return item;
      });
      setCommentState({
        commentPageNum: commentState.commentPageNum + 1,
        haveNext: false,
        commentList: newCommentList,
        commentFollowState: commentFollowState,
      });
    });
  };

  // 获取是否关注
  const getUserInfo = () => {
    APIPersonCenterUserDetail({
      anotherUserId: shotItem.userId
    }).then(resp => {
      if (resp.result) {
        setPostUser(resp.result);
      }
    });
  };

  // 去关注
  const toFollow = () => {
    APIUserFollow({
      userId: shotItem.userId
    }).then(resp => {
      if (resp.result) {
        getUserInfo();
      }
    }).finally(()=>{
      setShowAlert(false);
    });

  };

  // 下载图片
  const onDownloadClick = (e) => {
    e.stopPropagation();
    const imageList = state.item.images.split('|')
    setDownloading(true);
    imageList.forEach(item => {

      fetch(item + '?t=' + new Date().getTime(), {
      }).then(r => r.blob()).then(res => {
        console.log(res);
        const blob = new Blob([res]);
        const objectURL = URL.createObjectURL(blob);
        let link = document.createElement('a');
        link.download = state.item.title + `.png`;
        link.href = objectURL;
        link.click();
        URL.revokeObjectURL(objectURL);
      }).finally(() => {

      });
    });
    setDownloading(false);
  };

  // 提交打赏
  const onRewardFinish = (value) => {
    setRewardLoading(true);
    const query = {
      ...value,
      screenshotId: shotItem.id
    }
    APIDiscoverRewards(JSON.stringify(query)).then(resp => {
      if (resp.result) {
        message.success(resp.resultMessage)
        setRewardVis(false)
      } else {
        message.error(resp.resultMessage)
      }
    }).finally(() => {
      setRewardLoading(false)
    })
  };

  // 切换image index
  const onCarouseGoToNum = (num) => {
    setImageIndex(num);
    let abbreviated = document.getElementById('abbreviated-card');
    let abbreviatedList = abbreviated.children;
    abbreviatedIndexRef.current = num;
    abbreviatedList[num].scrollIntoView({behavior: 'smooth', block: 'nearest', inline: 'center'});
    carouseRef.current.goTo(num);
  };
  const onCarouseLeftClick = (e) => {
    e.stopPropagation();
    if (abbreviatedIndexRef.current === 0) {
      abbreviatedIndexRef.current = state.item.images.split("|").length - 1;
    } else {
      abbreviatedIndexRef.current -= 1;
    }
    let abbreviated = document.getElementById('abbreviated-card');
    let abbreviatedList = abbreviated.children;
    abbreviatedList[abbreviatedIndexRef.current].scrollIntoView({behavior: 'smooth', block: 'nearest', inline: 'center'});
    console.log(abbreviatedList);
    carouseRef.current.prev();
  };
  const onCarouseRightClick = (e) => {
    e.stopPropagation();
    if (abbreviatedIndexRef.current === state.item.images.split("|").length - 1) {
      abbreviatedIndexRef.current = 0;
    } else {
      abbreviatedIndexRef.current += 1;
    }
    let abbreviated = document.getElementById('abbreviated-card');
    let abbreviatedList = abbreviated.children;
    abbreviatedList[abbreviatedIndexRef.current].scrollIntoView({behavior: 'smooth', block: 'nearest', inline: 'center'});
    carouseRef.current.next();
  };


  useEffect(() => {
    setMobile(isMobile());

    if (isNFTShop) {
      getNFTGoodsDetails();
    } else {
      getGamieDetails();
    }
    getUserInfo();
  }, [shotItem, UserInfo]);

  return (
    <>
      <div className={styles.zone_page}>
        {
          state.loading ? (
            <Spin className='discover-loading' size='large' indicator={antIcon}/>
          ) : (
            <>
              {
                !mobile && (
                  <div className={styles.left_wrapper}>
                    {
                      (!imgLoad && state.item.isVideo === 0) && (
                        <Skeleton.Button active={true} className={styles.skeletion_box}/>
                      )
                    }
                    {
                      state.item.isVideo === 1 && (
                        <ReactPlayer id={"video"} className={styles.item_video}
                                     playing={true}
                                     controls={true}
                                     width={"100%"}
                                     volume={0.5}
                                     height={"100%"}
                                     url={state.item.images ? cdn(state.item.images.split("|")[0]) : ''}/>
                      )
                    }
                    {
                      state.item.isVideo === 0 && (
                        <div className={styles.nft_img_bg}
                             style={state.item.images ? {background: 'url("' + cdn(state.item.images.split("|")[imageIndex]) + '") center no-repeat'} : {}}>
                          <div className={styles.nft_img_bg_filter}>
                            <div className={styles.img_box_wrapper}>
                              <Carousel dots={false} afterChange={(num) => setImageIndex(num)} ref={carouseRef}>
                                {
                                  state.item.images && state.item.images.split("|").map((item, index) => (
                                    <div key={index} className={styles.img_box}>

                                      {/* <Image onError={() => onImgLoadChange()} onLoad={() => onImgLoadChange()}*/}
                                      <Image
                                        preview={{mask: (
                                            <div className={'ant-image-mask-info'}>
                                              <EyeOutlined />
                                              {t('preview')}
                                            </div>
                                          )}}
                                        onError={() => index === 0 ? onImgLoadChange() : null} onLoad={() => index === 0 ? onImgLoadChange() : null}
                                        className={styles.img}
                                        src={cdn(item)}/>
                                    </div>
                                  ))
                                }
                              </Carousel>
                              <div onClick={onCarouseLeftClick} className={styles.carousel_left_btn}></div>
                              <div onClick={onCarouseRightClick} className={styles.carousel_right_btn}></div>
                              <div
                                style={state.item.bestState === 0 && state.item.tags === "" ? {display: 'none'} : state.item.bestState === 0 && state.item.tags !== "Hot deal" ? {display: 'none'} : {}}
                                className={state.item.bestState === 1 ? styles.week_best : styles.hot_deal}>
                                {state.item.bestState === 1 ? t('weeklyBest') : state.item.tags === "Hot deal" ? t('hotDeal') : ""}
                              </div>
                            </div>
                            <div id={'abbreviated-card'} className={styles.abbreviated_img_wrapper}>
                              {
                                state.item.images && state.item.images.split("|").map((item, index) => (
                                  <div key={60 + index} onClick={() => onCarouseGoToNum(index)} className={styles.abbreviated_item}>
                                    <div className={styles.abbreviated_img}>
                                      <img src={item} />
                                    </div>
                                    <div className={`${styles.abbreviated_mask} ${imageIndex === index && styles.abbreviated_mask_none}`}></div>
                                  </div>
                                ))
                              }
                            </div>
                          </div>
                        </div>
                      )
                    }

                  </div>
                )
              }
              <div id={'scCon'} className={styles.right_wrapper}>
                <Affix offsetTop={0} onChange={onHeadOnTargetChange} target={() => mobile ? null : modalContainer}>
                  <div className={`${styles.header_wrapper} ${headAffixed && styles.header_wrapper_border}`}>
                    <div className={styles.header_user}>
                      <div onClick={(e) => toUserDetails(e)} className={styles.header_img}>
                        {/* <Popover overlayClassName={'gamie-zone-popover'} color='#fff' placement='bottomLeft' content={() => <UserProfile*/}
                        {/*  userId={state.item.userId} setStateChange={() => {*/}
                        {/*  commentState.commentFollowState[state.item.userId] = ! commentState.commentFollowState[state.item.userId];*/}
                        {/*  setCommentState({*/}
                        {/*    ...commentState,*/}
                        {/*    commentFollowState: {...commentState.commentFollowState}*/}
                        {/*  });*/}
                        {/* }}/>}>*/}
                        {/*  <Avatar className={styles.header_img_Avatar} src={state.gamieItem.headPic} />*/}
                        {/* </Popover>*/}
                        <Avatar className={styles.header_img_Avatar} src={state.gamieItem.headPic}/>

                      </div>
                      <div onClick={(e) => toUserDetails(e)} className={styles.user_name}>
                        {state.gamieItem.nickName}
                      </div>
                    </div>
                    <div className={styles.action_wra}>
                      {
                        postUser.isFollowed === 3 ?
                          <div onClick={()=>setShowAlert(true)}
                               className={`${styles.follow_button} ${styles.friends_button}`}>
                            Friends
                          </div> :
                          <div style={postUser.isFollowed === 2 ? {display: "none"} : {}} onClick={
                            ()=> postUser.isFollowed === 1 ? setShowAlert(true) : toFollow()
                          }
                               className={`${styles.follow_button} ${ postUser.isFollowed === 1 ? styles.friends_button : ""}`}>
                            {postUser.isFollowed === 1 ? t("unFollowUs") : t("followUs")}
                          </div>
                      }
                      <div className={styles.share_wra}>
                        <div onClick={onShowShare} className={styles.share_icon}></div>
                        <div className={styles.share_num}>{numFormat(state.shareNum)}</div>
                      </div>
                      <Popover  overlayClassName={'gamie-zone-popover'} overlayInnerStyle={{
                        border: '1px solid #2B3032',
                        borderRadius: 10,
                        width: 129,
                        display: 'flex',
                        justifyContent: 'center'
                      }} color={"#132024"}
                               content={() => <ActionMenu
                                 showReport={() => {
                                   setReportType(isNFTShop ? 3 : 0)
                                   setReportShow(true)
                                 }}
                                 checkUserPost={{
                                   item: state.item,
                                   user: state.user
                                 }} nftShop={isNFTShop} deleteNFT={() => setDeleteVis(true)} deletePost={deleteById}/>}
                                placement={mobile ? "bottomRight" : 'bottom'} trigger={"click"}
                      >
                        <div className={styles.action_menu_icon}></div>
                      </Popover>
                    </div>
                  </div>
                </Affix>
                {
                  mobile && (
                    <div className={styles.driver_line}></div>
                  )
                }
                {
                  (isNFTShop && mobile) && (
                    <div className={styles.nft_sole_wrapper}>
                      <div className={styles.igm_wra}>
                        <div className={styles.igm_icon}></div>
                        <div className={styles.igm_num}>IGM {state.item.price}</div>
                      </div>
                      <div className={styles.network_wra}>
                        <div className={state.item.networkType === 'BEP20' ? styles.btp_icon : styles.eth_icon}></div>
                        <div className={styles.text}>{state.item.networkType === 'BEP20' ? 'BSC' : 'ETH'}</div>
                      </div>
                    </div>
                  )
                }
                <div className={styles.content_wrapper}>
                  <div className={styles.title}>
                    {descRegTag(state.gamieItem.title)}
                  </div>
                  {
                    (state.item.tags && state.item.tags !== '') && (
                      <div className={styles.tag_wrapper}>
                        {
                          state.item.tags && state.item.tags.split(',').map((tag) => {
                            return tag && <div onClick={() => gotoQueryTag(tag)} className={styles.tag_item}>{'#' + tag}</div>
                          })
                        }
                      </div>
                    )
                  }

                  <div className={styles.content}>
                    {
                      state.item.desce && isNFTShop ? (
                        <>
                          {
                            descRegTag(state.item.desce)
                          }
                        </>
                      ) : (
                        <Paragraph ellipsis={
                          state.ellipsis ? {
                            rows: 3,
                            expandable: true,
                            symbol: 'view more',
                            onExpand: () => {
                              setState({
                                ...state,
                                ellipsis: false
                              });
                            }
                          } : false
                        } style={{color: '#ffffff', fontFamily: 'Montserrat'}}>
                          {
                            descRegTag(state.item.desce)
                          }
                        </Paragraph>
                      )
                    }
                  </div>
                  <div
                    className={styles.time_ago}>{getDateDiffComment(isNFTShop ? state.gamieItem.gmtCreateStamp : state.gamieItem.createTimeNum)}</div>
                </div>
                {
                  (isNFTShop && !mobile) && (
                    <div className={styles.nft_sole_wrapper}>
                      <div className={styles.igm_wra}>
                        <div className={styles.igm_icon}></div>
                        <div className={styles.igm_num}>IGM {state.item.price}</div>
                      </div>
                      <div className={styles.network_wra}>
                        <div className={state.item.networkType === 'BEP20' ? styles.btp_icon : styles.eth_icon}></div>
                        <div className={styles.text}>{state.item.networkType === 'BEP20' ? 'BSC' : 'ETH'}</div>
                      </div>
                    </div>
                  )
                }
                {
                  mobile && (
                    <div className={styles.zone_content}>
                      {
                        (!imgLoad && state.item.isVideo === 0) && (
                          <Skeleton.Button active={true} className={styles.skeletion_box}/>
                        )
                      }
                      {
                        state.item.isVideo === 1 && (
                          <div className={styles.item_video}>
                            <ReactPlayer id={"video"}
                                         playing={true}
                                         controls={true}
                                         width={"100%"}
                                         height={"100%"}
                                         volume={0.5}
                                         url={state.item.images ? cdn(state.item.images.split("|")[0]) : ''}/>
                          </div>

                        )
                      }
                      {
                        state.item.isVideo === 0 && (
                          <div className={styles.nft_img_bg}>
                            <div className={styles.nft_img_bg_filter}>
                              <div className={styles.img_box_wrapper}>
                                <Carousel dots={false} afterChange={(num) => {
                                  onCarouseGoToNum(num)
                                  setImageIndex(num)
                                }} ref={carouseRef}>
                                  {
                                    state.item.images && state.item.images.split("|").map((item, index) => (
                                      <div key={index} className={styles.img_box}>
                                        <Image  onError={() => index === 0 ? onImgLoadChange() : null} onLoad={() => index === 0 ? onImgLoadChange() : null}
                                               className={styles.img}
                                               src={cdn(item)}/>
                                      </div>
                                    ))
                                  }
                                </Carousel>
                                <div onClick={onCarouseLeftClick} className={styles.carousel_left_btn}></div>
                                <div onClick={onCarouseRightClick} className={styles.carousel_right_btn}></div>
                                <div
                                  style={state.item.bestState === 0 && state.item.tags === "" ? {display: 'none'} : state.item.bestState === 0 && state.item.tags !== "Hot deal" ? {display: 'none'} : {}}
                                  className={state.item.bestState === 1 ? styles.week_best : styles.hot_deal}>
                                  {state.item.bestState === 1 ? t('weeklyBest') : state.item.tags === "Hot deal" ? t('hotDeal') : ""}
                                </div>
                              </div>
                              <div id={'abbreviated-card'} className={styles.abbreviated_img_wrapper}>
                                {
                                  state.item.images && state.item.images.split("|").map((item, index) => (
                                    <div key={60 + index} onClick={() => onCarouseGoToNum(index)} className={styles.abbreviated_item}>
                                      <div className={styles.abbreviated_img}>
                                        <img src={item} />
                                      </div>
                                      <div className={`${styles.abbreviated_mask} ${imageIndex === index && styles.abbreviated_mask_none}`}></div>
                                    </div>
                                  ))
                                }
                              </div>
                            </div>
                          </div>
                        )
                      }
                    </div>
                  )
                }

                <div className={styles.driver_line}></div>
                <div className={styles.comments_wrapper}>
                  <div className={styles.title_wra}>
                    <div
                      className={styles.title}>{state.commentNum === null ? 0 : state.commentNum} {state.commentNum <= 1 ? t('comment') : t('comments')}</div>
                    <div className={styles.sub_title}>{t('discoverPostAddCommentHit').replaceAll('${name}', state.gamieItem.nickName)}</div>
                  </div>
                  <Affix offsetTop={67} onChange={onInputOnTargetChange} target={() => mobile ? null : modalContainer}>
                    <div className={styles.emo_wra} style={inputAffixed ? {paddingTop: 15} : {}}>
                      {
                        [3, 4, 5, 6, 7, 8, 9, 10].map((item) => {
                          return <div
                            style={{
                              width: "36px", flexShrink: "0", height: "36px",
                              margin: "0 0.35vw", backgroundSize: "contain", marginBottom: "15px", cursor: 'pointer'
                            }}
                            onClick={(e) => onAddComment(e, item)}
                            className={emojiMap[item]}>

                          </div>;
                        })
                      }
                    </div>
                    <div className={styles.input_wra}>
                      <Input.TextArea
                        ref={textAreaRefPC}
                        className={styles.input}
                        placeholder={t('postComment')}
                        autoSize={true}
                        value={inputValue}
                        onChange={(e) => onInputChange(e)}
                      />
                      {
                        adding ? (
                          <div className={styles.send_loading}>
                            <Spin className={styles.loading_icon} size='small' indicator={antSendIcon}/>
                          </div>
                        ) : (
                          <div onClick={e => onAddComment(e)} className={styles.send_icon}></div>
                        )
                      }
                    </div>
                  </Affix>
                  <div className={styles.comment_content_wra}>
                    {
                      commentState.commentList.map((item) => (
                        <CommentsWidget
                          followStateChange={commentState.commentFollowState[item.userId]}
                          item={item}
                          parentThumbClick={onParentsThumbCommentLike}
                          childThumbClick={onChildThumbCommentLike}
                          changeState={() => {
                            commentState.commentFollowState[item.userId] = !commentState.commentFollowState[item.userId];
                            setCommentState({
                              ...commentState,
                              commentFollowState: {...commentState.commentFollowState}
                            });
                          }}
                          replyComment={onSetCommentSend}
                          onViewMoreClick={onParentCommentViewMore}
                          isNFTShop={isNFTShop}
                          onDelete={onDeleteComment}
                          user={UserInfo}
                          onReport={onReportComment}
                        />
                      ))
                    }
                    {
                      (commentState.haveNext && !commentLoading) && (
                        <div onClick={onCommentViewMore} className={styles.view_more}>
                          <div className={styles.down_icon}></div>
                          <div className={styles.hit_txt}>View More</div>
                        </div>
                      )
                    }
                    {
                      commentLoading && (
                        <Spin className={styles.comment_loading_wra} size='large' indicator={antCommentIcon}/>
                      )
                    }

                  </div>
                </div>
                {
                  state.skuRsp && (
                    <>
                      <div className={styles.driver_line}></div>
                      <div className={styles.game_wrapper}>
                        <div className={styles.title}>{t('relatedGame')}</div>
                        <div onClick={gotoGameDetails} className={styles.game_box}>
                          <div className={styles.img_box}>
                            <img src={state.item.gameImage && cdn(gameBase + state.item.gameImage)}/>
                          </div>
                          <div className={styles.game_content}>
                            <div className={styles.game_name}>{state.item.alias}</div>
                            <div className={styles.game_detail_box}>
                        <span
                          style={state.skuRsp.sellingPrice !== state.skuRsp.originalPrice ? {} : {display: 'none'}}
                          className={styles.free_sale_tag}> {t('specialOffer')} </span>
                              <div className={styles.game_detail_sku_name}>{state.skuRsp.name}</div>
                              <div className={styles.game_detail_sku_price}>
                                {
                                  state.skuRsp.sellingCoins && state.skuRsp.sellingCoins > 0 ? (
                                    <span style={{color: '#F26E4C', fontSize: '19px'}}>
                                      {state.skuRsp.sellingCoins} {t('points')}
                                    </span>
                                  ) : (
                                    <div>
                                      <span style={{color: '#F26E4C', fontSize: '19px'}}>
                                        {state.item.priceSymbol} {state.skuRsp.sellingPrice}
                                      </span>
                                      <span style={state.skuRsp.sellingPrice !== state.skuRsp.originalPrice ? {
                                        marginLeft: '15px',
                                        fontSize: '11px',
                                        color: 'grey',
                                        textDecoration: 'line-through'
                                      } : {display: 'none'}}>
                                        {state.item.priceSymbol} {state.skuRsp.originalPrice}
                                      </span>
                                      {
                                        state.skuRsp.sellingPrice !== state.skuRsp.originalPrice ?
                                          <span style={{
                                            marginLeft: '10px',
                                            fontSize: '12px',
                                            color: '#F26E4C'
                                          }}>
                                     -{100 - (state.skuRsp.sellingPrice * 100 / state.skuRsp.originalPrice).toFixed(0)}%
                                    </span> : null
                                      }
                                    </div>
                                  )
                                }
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )
                }

              </div>
            </>
          )
        }
        {
          (mobile && isMobileEdit) && (
            <div onClick={mobileInputOnBlur} className={styles.input_mask}></div>
          )
        }
        {
          !state.loading && (
            <div className={styles.bottom_wrapper}>
              <div className={styles.action_wra} style={isMobileEdit ? {display: 'none'} : {}}>
                {
                  isNFTShop && (
                    <div className={styles.action_item}>
                      <div className={styles.view_icon}></div>
                      <div className={styles.num}>{numFormatNotRound(state.viewNum)}</div>
                    </div>
                  )
                }
                <div className={styles.action_item}>
                  <div onClick={onLikeStar}
                       className={state.isLike === 1 ? styles.like_icon_active : styles.like_icon}></div>
                  <div className={styles.num}>{numFormatNotRound(state.likeNum)}</div>
                </div>
                <div className={styles.action_item}>
                  <div className={styles.view_icon}></div>
                  <div className={styles.num}>{numFormatNotRound(state.viewNum)}</div>
                </div>
                <div className={styles.action_item}>
                  <Spin className={'imomo-spin'} spinning={downloading}>
                    <div onClick={onDownloadClick} className={styles.download_icon}></div>
                  </Spin>
                </div>
              </div>

              <div className={styles.make_wra} style={isMobileEdit ? {display: 'none'} : {}}>
                <div onClick={mobileMessageIconClick} className={styles.send_message_icon}></div>
                {
                  isNFTShop && (
                    <>
                      {
                        UserInfo.id === state.item.userId ? (
                          // <div className={styles.delete_button}>
                          //   Delete
                          // </div>
                          <></>
                        ) : state.item.status === 0 ? (
                          <div onClick={() => setConfirmVis(true)} className={styles.make_button}>Buy</div>
                        ) : (
                          <></>
                        )
                      }
                    </>
                  )
                }
                {
                  shotItem.userId !== UserInfo.id && (
                    <div onClick={() => setRewardVis(true)} className={styles.reward_button}>{t('sendATipBtnText')}</div>
                  )
                }
              </div>

              <div className={styles.bottom_input_wra} style={isMobileEdit ? {} : {width: 0, height: 0}}>
                <div className={styles.emo_wra}>
                  {
                    [3, 4, 5, 6, 7, 8, 9, 10].map((item) => {
                      return <div
                        key={item}
                        style={{
                          width: "40px", flexShrink: "0", height: "40px",
                          margin: "0 2vw", backgroundSize: "contain", marginBottom: "0", cursor: 'pointer'
                        }}
                        onClick={(e) => onAddComment(e, item)}
                        className={emojiMap[item]}>

                      </div>;
                    })
                  }
                </div>
                <div className={styles.input_wra}>
                  <Input.TextArea
                    ref={textAreaRefMobile}
                    className={styles.input}
                    placeholder={t('postComment')}
                    autoSize={true}
                    value={inputValue}
                    // onBlur={mobileInputOnBlur}
                    onChange={(e) => onInputChange(e)}
                  />
                  {
                    adding ? (
                      <div className={styles.send_loading}>
                        <Spin className={styles.loading_icon} size='small' indicator={antSendIcon}/>
                      </div>
                    ) : (inputValue === '' || inputValue.length < 0) ? (
                      <div onClick={e => setIsMobileEdit(false)} className={styles.input_close_icon}></div>
                    ) : (
                      <div onClick={e => onAddComment(e)} className={styles.send_icon}></div>
                    )
                  }
                </div>
              </div>

            </div>
          )
        }

        <div onClick={() => onClose?.()} className={styles.close_icon}></div>
      </div>
      <Modal
        footer={null} closable={false} onCancel={()=>setShowAlert(false)} className='black-modal' width={404}
        visible={showAlert} bodyStyle={{padding: '0'}}>
        <div className={`${styles.topic_upload_modal} ${styles.alert_wrap_unFollow}`}>
          <div className={styles.alert_wrap}>
            <div className={styles.title}>
              <div onClick={()=>setShowAlert(false)} className={styles.close_button}>
                <CloseOutlined/>
              </div>
            </div>
            <div className={styles.content}>
              Confirm to unfollow?
            </div>
            <div className={styles.bottom_button}>
              <div onClick={()=>setShowAlert(false)} className={styles.cancel_button}>Cancel</div>
              <div onClick={()=> toFollow()} className={styles.confirm_button}>Confirm</div>
            </div>
          </div>
        </div>
      </Modal>

      <Modal destroyOnClose={true} wrapClassName={'gamie-zone-modal-wrap'} width={549} closable={true} maskClosable={false} footer={null} closeIcon={<></>} centered visible={deleteCommentVis} className={'gamie-zone-modal'}>
        <div className={styles.confirm_mobile}>
          <div className={styles.title_wra}>
            <div className={styles.title}>Confirm</div>
            <div onClick={() => setDeleteCommentVis(false)} className={styles.close_icon}></div>
          </div>
          <div className={styles.content}>
            Are you sure you want to delete this comment?
          </div>
          <div className={styles.bottom_wra}>
            <div onClick={() => onDeleteComment(deleteCommentId, false)} className={styles.cancel}>Back</div>
            <Button onClick={() => onDeleteComment(deleteCommentId, true)} loading={confirmLoading} className={styles.sure}>Yes</Button>
          </div>
        </div>
      </Modal>
      <Modal destroyOnClose={true} wrapClassName={'gamie-zone-modal-wrap'} width={549} closable={true} maskClosable={false} footer={null} closeIcon={<></>} centered visible={deleteVis} className={'gamie-zone-modal'}>
        <div className={styles.confirm_mobile}>
          <div className={styles.title_wra}>
            <div className={styles.title}>Confirm</div>
            <div onClick={() => setDeleteVis(false)} className={styles.close_icon}></div>
          </div>
          <div className={styles.content}>
            Are you sure you want to delete this item?
          </div>
          <div className={styles.bottom_wra}>
            <div onClick={() => setDeleteVis(false)} className={styles.cancel}>No</div>
            <Button onClick={deleteNFTGoods} loading={confirmLoading} className={styles.sure}>Yes</Button>
          </div>
        </div>
      </Modal>
      <Modal destroyOnClose={true} wrapClassName={'gamie-zone-modal-wrap'} width={549} closable={true} maskClosable={false} footer={null} closeIcon={<></>} centered visible={personalVis} className={'gamie-zone-modal'}>
        <div className={styles.confirm_mobile}>
          <div className={styles.title_wra}>
            <div className={styles.title}>Confirm</div>
            <div onClick={closeAllModal} className={styles.close_icon}></div>
          </div>
          <div className={styles.content}>
            Payment made successfully! The seller will send the NFT to you soon.
          </div>
          <div className={styles.bottom_wra}>
            <div onClick={closeAllModal} className={styles.cancel}>Close</div>
            <Button onClick={toMyUserDetails} loading={confirmLoading} className={styles.sure}>View More</Button>
          </div>
        </div>
      </Modal>
      <Modal destroyOnClose={true} wrapClassName={'gamie-zone-modal-wrap'} width={549} closable={true} maskClosable={false} footer={null} closeIcon={<></>} centered visible={confirmVis} className={'gamie-zone-modal'}>
        <div className={styles.confirm_mobile}>
          <div className={styles.title_wra}>
            <div className={styles.title}>Confirm</div>
            <div onClick={() => setConfirmVis(false)} className={styles.close_icon}></div>
          </div>
          <div className={styles.content}>
            Are you sure you want to purchase this NFT?
          </div>
          <div className={styles.bottom_wra}>
            <div onClick={() => setConfirmVis(false)} className={styles.cancel}>Back</div>
            <Button onClick={buyNFTGoods} loading={confirmLoading} className={styles.sure}>Yes</Button>
          </div>
        </div>
      </Modal>
      <Modal destroyOnClose={true} wrapClassName={'gamie-zone-modal-wrap'} width={365} closable={true} maskClosable={false} footer={null} closeIcon={<></>} centered visible={rewardVis} className={'gamie-zone-modal'}>
        <div className={styles.reward_modal_page}>
          <div className={styles.header_wrapper}>
            <div className={styles.title}>{t('sendATipTitle')}</div>
          </div>
          <div className={styles.user_info_wrapper}>
            <div className={styles.head_pic}>
              <Avatar className={styles.header_img_Avatar} src={state.gamieItem.headPic}/>
            </div>
            <div className={styles.user_name}>
              {state.gamieItem.nickName}
            </div>
          </div>
          <Form colon={false} form={rewardFrom} onFinish={onRewardFinish} className={styles.form_sty} layout={"vertical"}>
            <Form.Item rules={[
              {required: true, message: 'Please enter the amount'},
              {
                validator: (_, value) => {
                  return value > igmBalance ? Promise.reject(t('sendTipRubyAmountInsufficientErrorText')) : Promise.resolve();
                }
              }
            ]} name={'ruby'}>
              <InputNumber placeholder={t('sendTipRubyAmount')} min={1} className={`${styles.input_wra} ${styles.input_wra_placeholder}`} />
            </Form.Item>

            <Form.Item name={'comment'}>
              <Input.TextArea autoSize={true} maxLength={200} showCount placeholder={t('sendTipMessagePlaceholder')} className={`${styles.text_area} ${styles.input_wra_placeholder}`} />
            </Form.Item>

            <div className={styles.btn_wrapper}>
              <div onClick={() => setRewardVis(false)} className={styles.cancel_btn}>
                {t('cancel')}
              </div>
              <Button htmlType={'submit'} loading={rewardLoading} className={styles.send_btn}>{t('sendTipSendBtnText')}</Button>
            </div>
          </Form>
        </div>
      </Modal>
      <ShareModal addShare={onAddShare} show={state.showShare} id={state.gamieId}
                  gameName={state.gameName}
                  isNFTShop={isNFTShop}
                  shotId={shotItem.id}
                  unShow={onCloseShare} item={state.gamieItem}/>
      <ReportReason reportUserId={reportUserId} reportType={reportType} postId={shotItem.id} onClose={() => setReportShow(false)} show={reportShow}/>
    </>
  );
};


export default GamezoneCard;
