import styles from "./index.module.scss";
import {useEffect, useState} from "react";


const MobileBottomSortSelect = ({ visible, closeBar, onChange, hasContent, index, id, nftsell }) => {

  const [selected, setSelected] = useState(index);

  const sortMap = nftsell ?
    {
      0:"Price: Low to High",
      1:"Price: High to Low",
    } :
    {
    0:"Popularity",
    5:"Release date",
    1:"Name: A to Z",
    2:"Name: Z to A",
    3:"Price: Low to High",
    4:"Price: High to Low",
  };


  const maskOnClick = (e) => {
    closeBar?.();
  };

  const onSelect = (index) => {
    setSelected(index);
    onChange?.(index);
    closeBar?.();
  };

  const maskTouchMove = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  useEffect(() => {
    if (visible) {
      document.getElementById('mobileSelectMask' + id).addEventListener("touchmove", (e) => {
        // 执行滚动回调
        maskTouchMove(e)
      }, {
        passive: false //  禁止 passive 效果
      });
    }
    setSelected(null);
  }, [visible])

  return (
    <div className={styles.common_wallet_connect}>
      <div className={ visible ? styles.common_wallet_wrapper : ` ${styles.common_wallet_wrapper} ${styles.common_wallet_wrapper_hidden} `}>
        <div className={styles.title}>Sort By</div>
        <div className={styles.content_wrapper}>
          {
            nftsell ?
            [0, 1].map(item=>{
              return <div onClick={() => onSelect(item)} className={`${styles.item} ${index === item && styles.item_active}`}>{sortMap[item]}</div>
            })
            :
            [0, 5, 1, 2, 3, 4].map(item=>{
              return <div onClick={() => onSelect(item)} className={`${styles.item} ${index === item && styles.item_active}`}>{sortMap[item]}</div>
            })
          }
        </div>
      </div>
      <div id={"mobileSelectMask" + id} onClick={maskOnClick} onTouchMove={maskTouchMove} className={ visible ? styles.common_share_mask : styles.display_none }>
      </div>
    </div>
  )
}

export default MobileBottomSortSelect
