import React, {useState, useEffect} from 'react';
import {
    Layout,
    Progress,
    Skeleton,
    message,
    Avatar,
    Select
  } from 'antd';
import {
    APIScreenShotsGiveLike,
    APIScreenShotsGetList,
    APINFTGiveLike
} from '../../api';
import {useRouter} from "next/router";
import styles from '../ImgCard/imgCard.module.scss';
import {numFormat} from '../../tools/action';
import defaultAvatarSrc1 from "@/imgs/defaultAvatar/1.svg";
import defaultAvatarSrc2 from "@/imgs/defaultAvatar/2.svg";
import defaultAvatarSrc3 from "@/imgs/defaultAvatar/3.svg";
import defaultAvatarSrc4 from "@/imgs/defaultAvatar/4.svg";
import {coinsNoticeActionNew} from "@/tools/action";
import OssImg from "@/components/OssImg";
import Img from "@/components/Img";
import useDispatchAction from '@/hooks/useDisptachAction';
import {setLoginModalShow, setLoginModalHidden, setLoginShowModal} from '@/redux/actions/home';


const redirectLogin = (func) => {
    // let hrefNow = window.location.href;
    // return window.location.href = "/login?redirect=" + encodeURI(hrefNow);
  func && func();
  return;
};
const cdn = (path) => {
    return path && path.replace(/igamie-web.s3.ap-southeast-1.amazonaws.com/, 'cdn.igamie.com');
};
const isMobile = () => {
    return false;
};
const ImgCardNft = (props) => {
  props.item.nickName = props.item.nickName === null || props.item.nickName === '' ? props.item.email && props.item.email.toString().split('@')[0] : props.item.nickName;
  const [item, setItem] = useState(props.item);
  const router = useRouter();
  const [showDetails, setShowDetails] = useState(false);
  const [onClick, setOnClick] = useState(false);
  const toUserDetails = (e) =>{
    e.stopPropagation();
    router.push('/personal-center/[userId]', `/personal-center/${item.userId}`,  {locale: router.locale} );
  };
  const dispatchAction = useDispatchAction({setLoginModalHidden, setLoginShowModal});


  const star = (e) => {
    e.stopPropagation();   // 阻止冒泡事件

    // if (localStorage.getItem('token') === null) {
    //   return redirectLogin();
    // }

    const token = localStorage.getItem("token");
    if (token === null || token === "") {
      // return redirectLogin();
      return redirectLogin(()=>dispatchAction.setLoginShowModal(true));
    }


    if (item.isLike === 1) {
      setItem({
        ...item,
        isLike: 0,
        likes: item.likes - 1,
      });
    } else {
      setOnClick(true);
      setItem({
        ...item,
        isLike: 1,
        likes: item.likes + 1,
        view: onClick ? item.view : item.view + 1
      });
    }
    APINFTGiveLike(JSON.stringify({
      businessId: props.item.id,
      productId: props.item.id
    })).then(resp => {
      if (resp.resultCode === 10006 || resp.resultCode === 10004 || resp.resultCode === 10007 || resp.resultCode === 10005) {
        redirectLogin(()=>dispatchAction.setLoginShowModal(true));
      }
      if (resp.result){
        // coinsNoticeActionNew();
      }
    }).catch(err => {
      message.error('connect err');
    });
  };
  const toDetails = () => {
    props.toDetails()
  };
  useEffect(() => {
    const screenShotId = localStorage.getItem('screenShotId');
    const deLikes = localStorage.getItem('likes');
    const deIsLike = localStorage.getItem('isLike');
    const deView = localStorage.getItem('view');

    if (screenShotId !== null && parseInt(screenShotId) === item.id && deLikes !== null && deIsLike !== null && deView !== null) {
      return setItem({
        ...item,
        isLike: parseInt(deIsLike),
        likes: parseInt(deLikes),
        view: parseInt(deView)
      });
    }
  }, []);
  const [imgLoad, setImgLoad] = useState(true);
  const loadSuccess = () => {
    setImgLoad(false);
  };

  const loadError = () => {
    setItem({
      ...item,
      headPic: '/2021-08-19/c62bfa34-9941-41f3-81d0-3733038a09d5.png'
    });
    setImgLoad(false);
  };

  const loadAvatar = (src) => {
    const defaultAvatarList = [defaultAvatarSrc1, defaultAvatarSrc2, defaultAvatarSrc3, defaultAvatarSrc4];
    const defaultIndex = Math.floor(Math.random() * 4);
    return (
      src ?
        cdn(src) :
        <img src={defaultAvatarList[defaultIndex].src} />
    );
  };

  const back = () => {
    setShowDetails((data) => {
      const newData = !data;
      return newData;
    });
  };
  return (
    <div onClick={() => toDetails()} className={styles.item}>
      <div className={styles.item_img_div}>
         <Img
              className={`${styles.item_img}`}
              src={item.productUrl} />
      </div>
      {/* <div
        style={item.bestState === 0 && item.tags === '' ||  item.price ? {display: 'none'} : item.bestState === 0 && item.tags !== 'Hot deal' ? {display: 'none'} : {}}
        className={item.bestState === 1 ? styles.week_best : styles.hot_deal}>
        {item.bestState === 1 ? 'Weekly Best' : item.tags === 'Hot deal' ? 'Hot Deal' : ''}
      </div> */}

      <div className={styles.post_info}>
          <div className={styles.nft_pro_info}>
            <div className={styles.description}>
              {item.description}
            </div>
            <div className={styles.price_info}>
              <div className={styles.price_wrapper}>
                <span className={styles.igm_logo}></span>
                <span className={styles.price_num}><span className={styles.igm_name}>IGM </span> <span className={styles.price}> {item.price}</span> </span>
              </div>
              <div className={styles.chain_wrapper}>
                {
                  item.networkType === 'ERC20' ?
                  <div className={styles.chain_w}>
                    <span className={styles.erc_icon}></span>
                    <span className={styles.name}>ETH</span>
                  </div> :
                  <div className={styles.chain_w}>
                    <span className={styles.bep_icon}></span>
                    <span className={styles.name}>BSC</span>
                  </div>
                }
              </div>
            </div>
          </div>
        <div className={styles.item_userInfo}>
          <div className={styles.share_div}>
            <div className={styles.shareIcon}/>
            <div>{numFormat(item.browseNum)}</div>
          </div>
          <div className={styles.userAvatar} onClick={(e)=>toUserDetails(e)}>
              <OssImg src={item.headPic} width={60} height={60} />
          </div>
          <div className={styles.star_div}>
            <div>{numFormat(item.likes)}</div>
            <div id={'star' + item.id} onClick={e => star(e)}
                className={item.isLike ? styles.star : styles.giveStar}></div>
          </div>
        </div>
      </div>
      <div className={styles.item_video_play}
           style={item.isVideo === 1 ? {} : {display: 'none'}}></div>
    </div>
  );
};


export default ImgCardNft;
