import {APIReportPost} from "@/api";
import {message, Modal} from "antd";
import styles from "./reportReason.module.scss";
import {CloseOutlined} from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { t } from 'i18next';
import useDispatchAction from '@/hooks/useDisptachAction';
import { setLoginModalShow, setLoginModalHidden } from '@/redux/actions/home';
import { isMobile } from "@/tools/action";


export const ReportReason = (props) =>{
  const reportReason = [
    t("reportSpam"),
    t("reportPersonalAttacks"),
    t("reportVulgarLanguage"),
    t("reportIllegalActivities"),
    t("reportAdultContent"),
    t("reportViolentContent"),
    t("reportDiscrimination"),
    t("reportSpreadingMisinformation"),
    t("reportCopyright"),
  ];
  const [Mobile, setMobile] = useState(false)
  const dispatchAction = useDispatchAction({setLoginModalHidden, setLoginModalShow});

  const report = (reason)=>{
    let query = {
      screenshotId:props.postId,
      reportReason:reason,
      type:props.reportType,
      reportedUserId: props.reportUserId,
    };
    APIReportPost(JSON.stringify(query)).then(resp => {
      if (resp.resultCode === 10006 || resp.resultCode === 10004 || resp.resultCode === 10007 || resp.resultCode === 10005) {
        props.onClose();
        dispatchAction.setLoginModalShow();
        return;
      }
      props.onClose();
      message.success("success");
    })

  };

  useEffect(() => {
    setMobile(isMobile())
  }, [])

  return (
    <Modal width={Mobile ? '6.5rem' : "447px"} footer={null} closable={false} centered className='IGM-modal IGMDeposit-modal' onCancel={props.onClose} visible={props.show}>
      <div>
        <div className={styles.report_title}>
          <div>
            Select report reason
          </div>
          <div style={{cursor:"pointer"}} onClick={props.onClose}>
            <CloseOutlined></CloseOutlined>
          </div>
        </div>
        <div className={styles.report_body}>
          {
            reportReason.map((item, index)=>{
              return <div key={index} onClick={()=>report(item)} className={styles.report_item}>{item}</div>;
            })
          }
        </div>
      </div>
    </Modal>
  );
};
